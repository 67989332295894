import React from 'react';

const ServiceModal = () => {
  return (
    <>
    
    <div style={{'--tw-translate-x': '-10%', maxWidth: '50rem', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridTemplateRows: 'repeat(2, 1fr)',gap: '1rem',padding: '0.5rem'}} className="dHctYWJzb2x1dGU dHctei0yMA dHctdy1zY3JlZW4 dHctbXQtMw dHctYmctd2hpdGU dHctYm9yZGVy dHctc2hhZG93LWxn dHctbGVmdC0xLzI dHctYm9yZGVyLWdyYXktNTA dHctcm91bmRlZC0yeGw dHctb3V0bGluZS1ub25l focus:dHctb3V0bGluZS1ub25l dHctdHJhbnNmb3Jt dHctb3BhY2l0eS0xMDA dHctc2NhbGUtMTAw">
      <div className="grid-item" id="headlessui-menu-item-:r1:" role="menuitem" tabindex="-1" data-headlessui-state="">
        <a style={{padding: '0.8rem'}} className="dHctYmxvY2s dHctdy1mdWxs dHctcHktNA dHctcm91bmRlZC14bA  dHctZ3JvdXA dHctdHJhbnNpdGlvbg dHctZHVyYXRpb24tMjAw dHctZWFzZS1pbi1vdXQ hover:dHctYmctcHVycGxlLTI1LzYw" 
        href="/servicios/lms-moodle-canvas">
          <h5 className="dHctdGV4dC1sZw dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtNjAw">Canvas y Moodle en la Nube</h5>
          <p className="dHctbXQtMQ dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw dHctb3BhY2l0eS05MA">
            Servicio LMS Canvas y Moodle fácilmente en la nube de Unilearn al mejor precio del mercado.
          </p>
        </a>
        <hr className="dHctbXktMQ dHctYm9yZGVyLXB1cnBsZS0yMDAvMzA sm:dHctbXktMg" />
      </div>
      <div className="grid-item" id="headlessui-menu-item-:r2:" role="menuitem" tabindex="-1" data-headlessui-state="">
        <a style={{padding: '0.8rem'}} 
              className="dHctYmxvY2s dHctdy1mdWxs dHctcHktNA dHctcm91bmRlZC14bA dHctZ3JvdXA dHctdHJhbnNpdGlvbg dHctZHVyYXRpb24tMjAw 
              dHctZWFzZS1pbi1vdXQ hover:dHctYmctcHVycGxlLTI1LzYw" 
              href="/servicios/servicios-procesos-aut">
          <h5 className="dHctdGV4dC1sZw dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtNjAw">Automatización de Procesos (RPA)</h5>
          <p className="dHctbXQtMQ dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw dHctb3BhY2l0eS05MA">
            Optimiza procesos administrativos con nuestros servicios de automatización, reduciendo errores y aumentando la eficiencia.
          </p>
        </a>
        <hr className="dHctbXktMQ dHctYm9yZGVyLXB1cnBsZS0yMDAvMzA sm:dHctbXktMg" />
      </div>
      <div className="grid-item" id="headlessui-menu-item-:r3:" role="menuitem" tabindex="-1" data-headlessui-state="">
        <a 
        style={{padding: '0.8rem'}} 
        className="dHctYmxvY2s dHctdy1mdWxs dHctcHktNA dHctcm91bmRlZC14bA dHctZ3JvdXA dHctdHJhbnNpdGlvbg dHctZHVyYXRpb24tMjAw
         dHctZWFzZS1pbi1vdXQ hover:dHctYmctcHVycGxlLTI1LzYw"
          href="/servicios/sharepoint-servicios-integracion">
          <h5 className="dHctdGV4dC1sZw dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtNjAw">Servicios de integración de SharePoint</h5>
          <p className="dHctbXQtMQ dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw dHctb3BhY2l0eS05MA">
          Soluciones personalizadas para conectar SharePoint con sus sistemas internos, como Teams, Microsoft Dynamics, y más.
          </p>
        </a>
        <hr className="dHctbXktMQ dHctYm9yZGVyLXB1cnBsZS0yMDAvMzA sm:dHctbXktMg" />
      </div>
      <div className="grid-item" id="headlessui-menu-item-:r4:" role="menuitem" tabindex="-1" data-headlessui-state="">
        <a style={{padding: '0.8rem'}} className="dHctYmxvY2s dHctdy1mdWxs dHctcHktNA dHctcm91bmRlZC14bA dHctZ3JvdXA dHctdHJhbnNpdGlvbg dHctZHVyYXRpb24tMjAw dHctZWFzZS1pbi1vdXQ hover:dHctYmctcHVycGxlLTI1LzYw" href="/programs/summer-camp">
          <h5 className="dHctdGV4dC1sZw dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtNjAw">Reportería e Inteligencia de Negocios</h5>
          <p className="dHctbXQtMQ dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw dHctb3BhY2l0eS05MA">
            Obtén insights valiosos con reportes avanzados y análisis de datos para tomar decisiones informadas.
          </p>
        </a>
        <hr className="dHctbXktMQ dHctYm9yZGVyLXB1cnBsZS0yMDAvMzA sm:dHctbXktMg" />
      </div>
    </div>
    </>
  );
};

export default ServiceModal;
