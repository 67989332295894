import React, { useState } from "react";
import ServiceModal from './ServiceModal';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dHctcHgtNA sm:dHctcHgtNg">
      <nav className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbWF4LXctc2NyZWVuLXhs  dHctbXgtYXV0bw" style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
        <div className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1iZXR3ZWVu dHctdy1mdWxs">
          <div className="dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1iZXR3ZWVu dHctaGlkZGVu lg:dHctZmxleA md:dHctc3BhY2UteC02 lg:dHctc3BhY2UteC0xMA">
            <a href="/">
              <div className="dHctcmVsYXRpdmU dHctcC0w.5 dHctZ3JvdXA">
                <span className="dHctcmVsYXRpdmU dHctei0xMA dHctdGV4dC1sZw dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNjAw">
                  Inicio
                </span>
                <span className="dHctYWJzb2x1dGU dHctYm90dG9tLTA dHctaC0x.5 dHctb3JpZ2luLWJvdHRvbQ dHctdHJhbnNmb3Jt dHctc2NhbGUteC0w dHctYmcteWVsbG93LTQwMA dHctcm91bmRlZC1sZw LXR3LWxlZnQtMQ LXR3LXJpZ2h0LTE dHctc2NhbGUteC0xMDA">
                </span>
              </div>
            </a>
           {/* <a href="/about">
              <div className="dHctcmVsYXRpdmU dHctcC0w.5 dHctZ3JvdXA">
                <span className="dHctcmVsYXRpdmU dHctei0xMA dHctdGV4dC1sZw dHctZm9udC1tZWRpdW0 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctdGV4dC1wdXJwbGUtNjAw dHctdGV4dC1wdXJwbGUtNzAw">
                  Servicios
                </span>
                <span className="dHctYWJzb2x1dGU dHctYm90dG9tLTA dHctaC0x.5 dHctb3JpZ2luLWJvdHRvbQ dHctdHJhbnNmb3Jt dHctc2NhbGUteC0w dHctYmcteWVsbG93LTQwMA dHctcm91bmRlZC1sZw LXR3LWxlZnQtMQ LXR3LXJpZ2h0LTE dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctc2NhbGUteC0xMDA">
                </span>
              </div>
            </a>
            */}
            <div className="dHctcmVsYXRpdmU" data-headlessui-state="">
              <button
                className="dHctb3V0bGluZS1ub25l focus:dHctb3V0bGluZS1ub25l"
                id="headlessui-menu-button-:Rmcba:"
                type="button"
                aria-haspopup="menu"
                aria-expanded="false"
                data-headlessui-state=""
                onClick={toggleMenu}
              >
                <div className="dHctcmVsYXRpdmU dHctcC0w.5 dHctZ3JvdXA">
                  <span className="dHctcmVsYXRpdmU dHctei0xMA dHctZmxleA dHctaXRlbXMtY2VudGVy dHctdGV4dC1sZw dHctZm9udC1tZWRpdW0 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctdGV4dC1wdXJwbGUtNjAw dHctdGV4dC1wdXJwbGUtNzAw">
                    Servicios
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="dHctbWwtMQ.5 dHctdy00.5 h-4.5 dHctdHJhbnNmb3Jt dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ"
                    >
                      <path d="M6 9l6 6l6 -6"></path>
                    </svg>
                  </span>
                  <span className="dHctYWJzb2x1dGU dHctYm90dG9tLTA dHctaC0x.5 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctb3JpZ2luLWJvdHRvbQ dHctdHJhbnNmb3Jt dHctc2NhbGUteC0w dHctYmcteWVsbG93LTQwMA dHctcm91bmRlZC1sZw LXR3LWxlZnQtMQ LXR3LXJpZ2h0LTE group-hover:dHctc2NhbGUteC0xMDA">
                  </span>
                </div>
              </button>
              {isOpen && (
              <ServiceModal />
               )}
            </div>
            <a href="/precios">
              <div className="dHctcmVsYXRpdmU dHctcC0w.5 dHctZ3JvdXA">
                <span className="dHctcmVsYXRpdmU dHctei0xMA dHctdGV4dC1sZw dHctZm9udC1tZWRpdW0 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctdGV4dC1wdXJwbGUtNjAw dHctdGV4dC1wdXJwbGUtNzAw">
                  Precios
                </span>
               <span className="dHctYWJzb2x1dGU dHctYm90dG9tLTA dHctaC0x.5 dHctb3JpZ2luLWJvdHRvbQ dHctdHJhbnNmb3Jt dHctc2NhbGUteC0w
                           dHctYmcteWVsbG93LTQwMA dHctcm91bmRlZC1sZw LXR3LWxlZnQtMQ LXR3LXJpZ2h0LTE dHctZHVyYXRpb24tMzAw 
                           dHctZWFzZS1pbi1vdXQ group-hover:dHctc2NhbGUteC0xMDA">
              </span>
              </div>
            </a>
            <a href="/clientes">
              <div className="dHctcmVsYXRpdmU dHctcC0w.5 dHctZ3JvdXA">
                  <span className="dHctcmVsYXRpdmU dHctei0xMA dHctdGV4dC1sZw dHctZm9udC1tZWRpdW0 dHctZHVyYXRpb24tMzAw
                   dHctZWFzZS1pbi1vdXQ group-hover:dHctdGV4dC1wdXJwbGUtNjAw dHctdGV4dC1wdXJwbGUtNzAw">
                    Clientes
                  </span>
                  <span className="dHctYWJzb2x1dGU dHctYm90dG9tLTA dHctaC0x.5 dHctb3JpZ2luLWJvdHRvbQ dHctdHJhbnNmb3Jt 
                  dHctc2NhbGUteC0w dHctYmcteWVsbG93LTQwMA dHctcm91bmRlZC1sZw LXR3LWxlZnQtMQ LXR3LXJpZ2h0LTE 
                  dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctc2NhbGUteC0xMDA">
                    </span>
              </div>
            </a>
            <a href="/contactenos">
                <div className="dHctcmVsYXRpdmU dHctcC0w.5 dHctZ3JvdXA">
                    <span className="dHctcmVsYXRpdmU dHctei0xMA dHctdGV4dC1sZw dHctZm9udC1tZWRpdW0 
                      dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctdGV4dC1wdXJwbGUtNjAw 
                      dHctdGV4dC1wdXJwbGUtNzAw">
                        Contacto
                    </span>
                    <span className="dHctYWJzb2x1dGU dHctYm90dG9tLTA dHctaC0x.5 dHctb3JpZ2luLWJvdHRvbQ dHctdHJhbnNmb3Jt 
                        dHctc2NhbGUteC0w dHctYmcteWVsbG93LTQwMA dHctcm91bmRlZC1sZw LXR3LWxlZnQtMQ LXR3LXJpZ2h0LTE
                         dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctc2NhbGUteC0xMDA">
                    </span>
                    </div>
                  </a>
                </div>
                <div className="dHctaGlkZGVu lg:dHctYmxvY2s">
                    <a className="dHctZm9udC1tZWRpdW0 dHctcmVsYXRpdmU dHctbGVhZGluZy1ub3JtYWw dHctaW5saW5lLWZsZXg 
                    dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctcm91bmRlZC1mdWxs
                     dHctb3V0bGluZS1ub25l dHctZ3JvdXA dHctdGV4dC1wdXJwbGUtOTAw dHctYmcteWVsbG93LTUwMA 
                     hover:dHctYmcteWVsbG93LTYwMA dHctcHgtOA dHctdGV4dC1sZw" style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }} href="/contactenos">
                      Conversémos
                    </a>
                  </div>
                  <div className="dHctZmxleC1ncm93LTA dHctZmxleC1zaHJpbmstMA dHctYmxvY2s dHctdy00OA lg:dHctaGlkZGVu sm:dHctdy01Mg">
                      <a href="/">
                        <img alt="Bright" loading="lazy" width="562" height="178" decoding="async" data-nimg="1" 
                          className="dHctaC1hdXRv" style={{ color: 'transparent' }} 
                          srcset="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fbright-logo.095e684b.png&amp;w=640&amp;q=75 1x, /_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fbright-logo.095e684b.png&amp;w=1200&amp;q=75 2x" 
                          src="/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fbright-logo.095e684b.png&amp;w=1200&amp;q=75" />
                      </a>
                  </div>
                  <div className="dHctYmxvY2s lg:dHctaGlkZGVu">
                      <div data-headlessui-state="">
                          <button className="dHctcmVsYXRpdmU dHctei01MA dHctdy02 dHctaC01 dHctdHJhbnNpdGlvbg dHctZHVyYXRpb24tNTAw 
                          dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctcm90YXRlLTA dHctY3Vyc29yLXBvaW50ZXI dHctZ3JvdXA 
                          focus:dHctb3V0bGluZS1ub25l" aria-label="Toggle Navigation" 
                          type="button" aria-expanded="false" data-headlessui-state="" id="headlessui-popover-button-:Rb4ba:">
                            <span className="dHctYWJzb2x1dGU dHctYmxvY2s dHctaC0x dHctdHJhbnNpdGlvbi1hbGw dHctZHVyYXRpb24tMzAw 
                            dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctcm90YXRlLTA dHctcm91bmRlZC1mdWxs dHctb3BhY2l0eS0xMDA 
                            dHctdG9wLTA dHctbGVmdC0w dHctdy1mdWxs dHctYmctcHVycGxlLTkwMA group-hover:dHctYmctcHVycGxlLTYwMA">
                            </span>
                            <span className="dHctYWJzb2x1dGU dHctbGVmdC0w dHctYmxvY2s dHctdy1mdWxs dHctaC0x dHctdHJhbnNpdGlvbi1hbGw 
                            dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctcm91bmRlZC1mdWxs dHctb3BhY2l0eS0xMDA 
                            dHctdG9wLTI group-hover:dHctYmctcHVycGxlLTYwMA dHctcm90YXRlLTA dHctYmctcHVycGxlLTkwMA 
                            group-hover:dHctYmctcHVycGxlLTYwMA"></span>
                            <span className="dHctYWJzb2x1dGU dHctbGVmdC0w dHctYmxvY2s dHctdy1mdWxs dHctaC0x dHctdHJhbnNpdGlvbi1hbGw 
                            dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctcm91bmRlZC1mdWxs dHctb3BhY2l0eS0xMDA 
                            dHctdG9wLTI group-hover:dHctYmctcHVycGxlLTYwMA dHctcm90YXRlLTA dHctYmctcHVycGxlLTkwMA 
                            group-hover:dHctYmctcHVycGxlLTYwMA"></span><span className="dHctYWJzb2x1dGU dHctYmxvY2s 
                            dHctaC0x dHctdHJhbnNpdGlvbi1hbGw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt 
                            dHctcm90YXRlLTA dHctcm91bmRlZC1mdWxs dHctb3BhY2l0eS0xMDA group-hover:dHctYmctcHVycGxlLTYwMA 
                            dHctdG9wLTQ dHctbGVmdC0w dHctdy1mdWxs dHctYmctcHVycGxlLTkwMA group-hover:dHctYmctcHVycGxlLTYwMA"></span>
                          </button>
                        </div>
                        <div style={{  position: 'fixed',  top: '1px',  left: '1px',  width: '1px',  height: '0',  padding: '0',
                                      margin: '-1px',  overflow: 'hidden',  clip: 'rect(0, 0, 0, 0)',  whiteSpace: 'nowrap',
                                      borderWidth: '0',  display: 'none'}}></div>
                        </div></div></nav></div>
  );
};

export default Navbar;
