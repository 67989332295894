import React from 'react';
import './Menu.css'; // Adjust the path if necessary
import Navbar from './Navbar';
import ContactDetails from '../Contact/ContactDetails';
const Menu = () => {
  return (
    <div className="dHctYmctcHVycGxlLTI1">
        <div className="dHctaGlkZGVu dHctcHgtNA lg:dHctYmxvY2s sm:dHctcHgtNg">
        <div className="pt-2 pb-2 dHctcmVsYXRpdmU dHctbWF4LXctc2NyZWVuLXhs dHctbXgtYXV0bw dHctYm9yZGVyLWI dHctYm9yZGVyLXB1cnBsZS0yMDAvMzA">
            <div className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1iZXR3ZWVu">
            <div className="dHctZmxleC1ncm93LTA dHctZmxleC1zaHJpbmstMA w-40">
                <a href="/">
                <img
                    alt="Bright"
                    loading="lazy"
                    width="562"
                    height="178"
                    decoding="async"
                    data-nimg="1"
                    className="dHctaC1hdXRv"
                    style={{ color: 'transparent' }}
                    src="/Unilearn.png"             />
                </a>
            </div>
            <ContactDetails displayMode={'horizontal'} />
            </div>
        </div>
    </div>
    <Navbar />
</div>
    );
};

export default Menu;
