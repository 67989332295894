import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [formStatus, setFormStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFormStatus('');

    const serviceID = 'default_service';
    const templateID = 'template_vy4ppcj';
    const publicKey = 'g21xW37teElw800Mc';

    emailjs.sendForm(serviceID, templateID, e.target, publicKey)
      .then(() => {
        setFormStatus('Su mensaje ha sido enviado.');
      }, (err) => {
        setFormStatus('Error al enviar el mensaje: ' + JSON.stringify(err));
      })
      .finally(() => {
        setIsSubmitting(false);
        e.target.reset(); // Optional: Reset the form fields after submission
      });
  };

  
  return (
    <section className="dHctcHgtNA dHctcGItMTI dHctb3ZlcmZsb3ctaGlkZGVu sm:dHctcHgtNg lg:dHctcHgtOA dHctYmctZ3JhZGllbnQtdG8tYg dHctZnJvbS1wdXJwbGUtMjU dHctdG8tcHVycGxlLTUw pt-12">
        <div className="dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctZ3JpZA lg:dHctZ3JpZC1jb2xzLTI lg:dHctZ2FwLTEw xl:dHctZ2FwLTMy lg:dHctbWF4LXctc2NyZWVuLXhs ">
            <div className="">
                <div>
                    <span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw 
                    dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">
                        Contáctenos Hoy
                    </span>
                </div>
                    <h1 className="dHctbWF4LXctbWQ dHctbXQtNA dHctdGV4dC1wdXJwbGUtOTAw h1">Nos Encantaría Escuchar de Ti</h1>
                    <p className="dHctbWF4LXctbGc dHctbXQtMw dHctdGV4dC14bA dHctbGVhZGluZy1yZWxheGVk dHctdGV4dC1wdXJwbGUtODAw">
                            Si tienes alguna pregunta, comentario, o simplemente quieres saludarnos, estamos aquí para escucharte. Contáctanos a través del formulario a continuación, y te responderemos lo antes posible. Tus opiniones e inquietudes son importantes para nosotros, ¡y estamos emocionados de conectarnos contigo!                    </p>
                </div>
                <div className="dHctcmVsYXRpdmU">
                <img alt="" 
                    fetchpriority="high" width="300" height="264" decoding="async" data-nimg="1" 
                    className="dHctYWJzb2x1dGU lg:dHctaGlkZGVu LXR3LXJpZ2h0LTE2 LXR3LXRvcC0xMg sm:LXR3LXRvcC0xNg dHctdy04MA lg:dHctbGVmdC0xNA lg:LXR3LXRvcC0xNg lg:dHctdy0zNg dHctb3BhY2l0eS02MA" 
                    style={{ color: 'transparent' }}
                    src="/_next/static/media/dots-large-grid.1230c423.svg" />
                <img alt="" loading="lazy" width="370" height="432" decoding="async" data-nimg="1" 
                    className="dHctYWJzb2x1dGU dHctaGlkZGVu dHctdy00MA dHctb3BhY2l0eS03NQ lg:dHctYmxvY2s LXR3LXJpZ2h0LTE2 LXR3LXRvcC0xNg lg:dHctbGVmdC0xNA lg:LXR3LXRvcC0xNg lg:dHctdy0zNg" 
                    style={{ color: 'transparent' }}                    
                    src="/static/media/dots-grid.3f2d5da3.svg" 
                />
                <img alt="" loading="lazy" width="152" height="378" decoding="async" data-nimg="1" 
                    className="dHctYWJzb2x1dGU dHctaGlkZGVu dHctdy0yMA dHctcm90YXRlLTkw dHctb3BhY2l0eS03NQ lg:dHctYmxvY2s LXR3LXJpZ2h0LTE2 dHctdG9wLTEvMg" 
                    style={{ color: 'transparent' }} src="/static/media/dots-strip.fc580823.svg" 
                />
                <div className="dHctcmVsYXRpdmU dHctei0xMA dHctdy1mdWxs dHctcHgtNA dHctcHktMTA dHctbXgtYXV0bw dHctYmctd2hpdGU dHctc2hhZG93LXhs dHctcm91bmRlZC0zeGw lg:dHctbXItMA lg:dHctbWwtYXV0bw sm:dHctcC0xNg lg:dHctcC0xMg "><div>
                    <h3 className="dHctdGV4dC0yeGw dHctZm9udC1ib2xk dHctdGV4dC1wdXJwbGUtOTAw">Envíenos un mensaje</h3>
                    <p className="dHctdGV4dC1wdXJwbGUtODAw dHctbXQtMA.5 dHctdGV4dC1vcGFjaXR5LTkw">
                            Le responderemos en menos de 24 horas.
                    </p>
                </div><form className="dHctbXQtOA"  onSubmit={handleSubmit}>
                    <div className="">
                        <label for="from_name" className="dHctbWwtMA.5 dHctdGV4dC1wdXJwbGUtOTAw dHctZm9udC1tZWRpdW0 dHctdGV4dC1zbQ">Nombre *</label>
                        <input id="name" type="text" placeholder="Su nombre" className="dHctdy1mdWxs dHctcC00 dHctbXQtMg dHctdGV4dC1zbQ dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctcGxhY2Vob2xkZXItcHVycGxlLTcwMA dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctYm9yZGVyLTI
                                                                                     dHctb3V0bGluZS1ub25l dHctaC0xNA dHctcGxhY2Vob2xkZXItb3BhY2l0eS03MA dHctcm91bmRlZC0yeGw dHctYm9yZGVyLXB1cnBsZS01MA focus:dHctYm9yZGVyLXB1cnBsZS0yMDA
                                                                                     focus:dHctcmluZy1wdXJwbGUtMjAw focus:dHctb3V0bGluZS1ub25l"
                                                                                      required="" name="from_name" />
                    </div>
                    <div className="dHctbXQtNg"><label for="email" className="dHctbWwtMA.5 dHctdGV4dC1wdXJwbGUtOTAw dHctZm9udC1tZWRpdW0 dHctdGV4dC1zbQ">
                        Correo Electrónico *</label>
                <input id="email" type="email" placeholder="Correo Institucional" className="dHctdy1mdWxs dHctcC00 dHctbXQtMg dHctdGV4dC1zbQ dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctcGxhY2Vob2xkZXItcHVycGxlLTcwMA dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctYm9yZGVyLTI dHctb3V0bGluZS1ub25l dHctaC0xNA dHctcGxhY2Vob2xkZXItb3BhY2l0eS03MA dHctcm91bmRlZC0yeGw dHctYm9yZGVyLXB1cnBsZS01MA focus:dHctYm9yZGVyLXB1cnBsZS0yMDA focus:dHctcmluZy1wdXJwbGUtMjAw focus:dHctb3V0bGluZS1ub25l" required="" name="email" />
                </div><div className="dHctbXQtNg"><label for="phone" className="dHctbWwtMA.5 dHctdGV4dC1wdXJwbGUtOTAw dHctZm9udC1tZWRpdW0 dHctdGV4dC1zbQ">Teléfono</label>
                <input id="phone" type="text" placeholder="+56992235452" className="dHctdy1mdWxs dHctcC00 dHctbXQtMg dHctdGV4dC1zbQ dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctcGxhY2Vob2xkZXItcHVycGxlLTcwMA dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctYm9yZGVyLTI dHctb3V0bGluZS1ub25l dHctaC0xNA dHctcGxhY2Vob2xkZXItb3BhY2l0eS03MA dHctcm91bmRlZC0yeGw dHctYm9yZGVyLXB1cnBsZS01MA focus:dHctYm9yZGVyLXB1cnBsZS0yMDA focus:dHctcmluZy1wdXJwbGUtMjAw focus:dHctb3V0bGluZS1ub25l" required="" name="phone" />
                </div>
                <div className="dHctbXQtNg"><label for="message" className="dHctbWwtMA.5 dHctdGV4dC1wdXJwbGUtOTAw dHctZm9udC1tZWRpdW0 dHctdGV4dC1zbQ">Mensaje *</label>
                    <textarea id="message" name="message" placeholder="Quiero conocer más de su servicio, agendemos una reunión" rows="5" 
                        className="dHctdy1mdWxs dHctcC00 dHctbXQtMg dHctdGV4dC1zbQ dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctcGxhY2Vob2xkZXItcHVycGxlLTcwMA dHctZHVyYXRpb24tMzAw 
                        dHctZWFzZS1pbi1vdXQ dHctYm9yZGVyLTI dHctb3V0bGluZS1ub25l dHctcGxhY2Vob2xkZXItb3BhY2l0eS03MA dHctcm91bmRlZC0yeGw dHctYm9yZGVyLXB1cnBsZS01MA 
                        focus:dHctYm9yZGVyLXB1cnBsZS0yMDA focus:dHctcmluZy1wdXJwbGUtMjAw focus:dHctb3V0bGluZS1ub25l" required=""></textarea>
                </div>
                <div className="dHctZmxleA dHctanVzdGlmeS1zdGFydA dHctbXQtNg">
                        <button  type="submit"
                        className="dHctZm9udC1tZWRpdW0 dHctcmVsYXRpdmU dHctbGVhZGluZy1ub3JtYWw dHctaW5saW5lLWZsZXg dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctZHVyYXRpb24tMzAw
                         dHctZWFzZS1pbi1vdXQ dHctcm91bmRlZC1mdWxs dHctb3V0bGluZS1ub25l dHctZ3JvdXA dHctdGV4dC1wdXJwbGUtOTAw dHctYmcteWVsbG93LTUwMA hover:dHctYmcteWVsbG93LTYwMA dHctcHgtOA
                          dHctcHktMw.5 dHctdGV4dC1sZw"
                          disabled={isSubmitting}
                          >
                          {isSubmitting ? 'Enviando...' : 'Enviar un mensaje'}
          </button></div></form>
          {formStatus && <p>{formStatus}</p>}
          </div></div></div></section>
  );
};

export default ContactForm;
