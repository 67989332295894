import React from 'react';
import './styles/global.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage'; // Ensure the import path is correct
import Contact from './pages/Contact'; // Ensure the import path is correct
import Layout from './components/Layout'; // Ensure the import path is correct
import ServiciosLMS from './pages/ServiciosLMS'; // Ensure the import path is correct
import ServiciosProcesosAut from './pages/ServiciosProcesosAut'
import Precios from './pages/Precios'
import Clientes from './pages/Clientes';
import SharePointIntegration from './pages/SharePointIntegracion'
const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contactenos" element={<Contact />} />
        <Route path="/servicios/lms-moodle-canvas" element={<ServiciosLMS />} />
        <Route path="/servicios/servicios-procesos-aut" element={<ServiciosProcesosAut />} />
        <Route path="/precios" element={<Precios />} />
        <Route path="/clientes" element={<Clientes />} />
        <Route path="/servicios/sharepoint-servicios-integracion" element={<SharePointIntegration />} />
        
        </Routes>
      </Layout>
    </Router>
  );
};

export default App; // Default export
