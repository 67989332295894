import React from 'react';
import Menu from './Menu/Menu';
import Footer from './Footer';
const Layout = ({ children }) => {
  return (
    <div className="dHctZm9udC1zYW5z __variable_d05349 flex flex-col min-h-screen">
      <Menu />
      
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
