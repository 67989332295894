import React from 'react';
const style = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    color: 'transparent',
  };
  
const Section = () => {
  return (
   
   <section className="dHctcHgtNA dHctcGItMTY dHctb3ZlcmZsb3ctaGlkZGVu dHctYmcteWVsbG93LTEwMA sm:dHctcGItMjQ sm:dHctcHgtNg lg:dHctcHgtOA"><div className="dHctbWF4LXctc2NyZWVuLXhs dHctbXgtYXV0bw">
    <div className="dHctcmVsYXRpdmU">
            <h2 className="dHctbWF4LXctNHhs dHctbXgtYXV0bw dHctdGV4dC1jZW50ZXI dHctdGV4dC1wdXJwbGUtOTAw h2">
            Transformación Digital para Instituciones Educativas
            </h2>
        <p className="dHctbWF4LXctM3hs dHctbXgtYXV0bw dHctbXQtNA dHctdGV4dC14bA dHctbGVhZGluZy1yZWxheGVk dHctdGV4dC1jZW50ZXI dHctdGV4dC1wdXJwbGUtODAw">
        Nuestra empresa ofrece soluciones de software especializadas para instituciones educativas, enfocándonos en la automatización de procesos, 
        digitalización de documentos, gestión de aprendizaje, y análisis avanzado. A través de nuestras integraciones con Microsoft,
         Google, y plataformas LMS como Canvas y Moodle, optimizamos la operación educativa, facilitando un entorno de aprendizaje moderno, eficiente y basado en datos.
            </p><div className="dHctbWF4LXctM3hs dHctbXgtYXV0bw dHctbXQtMTI"><ul className="dHctZmxleA dHctZmxleC13cmFw dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI LXR3LW14LTM LXR3LW15LTI dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw"><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" loading="lazy" width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Inteligencia Artificial</span></li><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" 
    loading="lazy" 
    width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Automatización de Procesos</span></li><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" loading="lazy" width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Digitalización de Documentos</span></li><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" loading="lazy" width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Canvas LMS</span></li><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" loading="lazy" width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Moodle LMS</span></li><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" loading="lazy" width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Reportería &amp; Inteligencia de Negocios</span></li><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" loading="lazy" width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Seguridad y Cumplimiento Normativo</span></li></ul></div></div><div className="dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMTY lg:dHctbWF4LXctbm9uZQ lg:dHctZ3JpZA lg:dHctZ3JpZC1jb2xzLTEy lg:dHctZ2FwLXgtMTQ xl:dHctZ2FwLXgtMjA 2xl:tw-gap-x-24 sm:dHctbXQtMjA lg:dHctbXQtMjQ"><div className="dHctcmVsYXRpdmU lg:dHctY29sLXNwYW4tNg"><div className="dHctcmVsYXRpdmU sm:dHctcGwtMzY lg:dHctcGwtMjA xl:dHctcGwtMzI"><div className="dHctcmVsYXRpdmU dHctYXNwZWN0LXctMw dHctYXNwZWN0LWgtNA dHctcm91bmRlZC0yeGw">
    <img alt="Kid stacking blocks" loading="lazy" decoding="async" data-nimg="fill" 
    className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctcm91bmRlZC0yeGw" 
    style={style} 
    sizes="(min-width: 1280px) 29.5rem, (min-width: 1024px) calc(50vw - 8.75rem), (min-width: 640px) 27rem, calc(100vw - 2rem)" 
    src="/static/media/images/view-of-hands-of-analysts-or-brokers-with-gadgets-2023-11-27-04-56-49-utc.JPG"/>
    </div></div></div><div className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctbXQtMTY lg:dHctbXQtMA lg:dHctY29sLXNwYW4tNg sm:dHctbXQtNDQ"><div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">Automatización de Procesos & RPA</span></div>
        <h3 className="dHctbXQtNA dHctdGV4dC1wdXJwbGUtOTAw sm:dHctbXQtNQ h3">
        Automatización de Procesos & RPA para Instituciones Educativas
        </h3>
    <p className="dHctbWF4LXctMnhs dHctbXQtMw dHctdGV4dC1sZw dHctbGVhZGluZy1sb29zZQ dHctdGV4dC1wdXJwbGUtODAw">
    Optimiza la eficiencia operativa de tu institución educativa con nuestra solución de Automatización de Procesos y RPA (Automatización Robótica de Procesos). Implementamos tecnologías que automatizan tareas repetitivas y administrativas, reduciendo errores, liberando tiempo para el personal y permitiendo un enfoque más centrado en la enseñanza y el aprendizaje. Desde la gestión de inscripciones hasta la administración de datos, nuestros servicios mejoran la productividad y la precisión en cada etapa del proceso educativo.</p><div className="dHctbXQtNg"><a className="dHctZm9udC1tZWRpdW0 dHctcmVsYXRpdmU dHctbGVhZGluZy1ub3JtYWw dHctaW5saW5lLWZsZXg dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctcm91bmRlZC1mdWxs dHctb3V0bGluZS1ub25l dHctZ3JvdXA dHctdGV4dC13aGl0ZQ dHctYmctcHVycGxlLTYwMA hover:dHctYmctcHVycGxlLTUwMA dHctcHgtNQ dHctcHktMg.5 dHctdGV4dC1iYXNl" href="/about">Conoce más<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctbWwtMw group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ"><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg></a></div></div></div><div className="dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMTY lg:dHctbWF4LXctbm9uZQ lg:dHctZ3JpZA lg:dHctZ3JpZC1jb2xzLTEy lg:dHctZ2FwLXgtMTQ xl:dHctZ2FwLXgtMjA 2xl:tw-gap-x-24 sm:dHctbXQtNDQ lg:dHctbXQtNTY xl:dHctbXQtNjA 2xl:tw-mt-64"><div className="dHctcmVsYXRpdmU lg:dHctY29sLXNwYW4tNg lg:dHctb3JkZXItMg"><div className="dHctcmVsYXRpdmU sm:dHctcHItMzY lg:dHctcHItMjA xl:dHctcHItMzI"><div className="dHctcmVsYXRpdmU dHctYXNwZWN0LXctMw dHctYXNwZWN0LWgtNA dHctcm91bmRlZC0yeGw">
    <img alt="Child covered in paint" loading="lazy" decoding="async" data-nimg="fill" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctcm91bmRlZC0yeGw" 
    style={style} sizes="(min-width: 1280px) 29.5rem, (min-width: 1024px) calc(50vw - 8.75rem), (min-width: 640px) 27rem, calc(100vw - 2rem)" 
    src="/static/media/images/illustration-stationery-on-office-desk-2023-11-27-05-26-35-utc.jpg" />
    </div><div className="dHctYWJzb2x1dGU dHctaGlkZGVu sm:dHctYmxvY2s sm:dHctdy03Mg sm:dHctaC03Mg lg:dHctdy02NA lg:dHctaC02NA xl:dHctdy03Mg xl:dHctaC03Mg 2xl:tw-w-80 2xl:tw-h-80 dHctcm91bmRlZC0zeGw dHctcmlnaHQtMA dHctdG9wLTA sm:LXR3LXRyYW5zbGF0ZS15LTEvMw">
    <img alt="Children eating" loading="lazy" decoding="async" data-nimg="fill" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctdy1mdWxs dHctaC1mdWxs dHctcm91bmRlZC0zeGw" 
    style={style} sizes="(min-width: 1536px) 20rem, (min-width: 1280px) 18rem, (min-width: 1024px) 16rem, (min-width: 640px) 18rem" 
    src="/static/media/universitarios-612x612.jpg" />
    </div></div></div><div className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctbXQtMTY lg:dHctbXQtMA lg:dHctY29sLXNwYW4tNg sm:dHctbXQtMjA lg:dHctb3JkZXItMQ"><div>
        <span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">
            LMS Canvas & Moodle
        </span>
        </div>
        <h3 className="dHctbXQtNA dHctdGV4dC1wdXJwbGUtOTAw sm:dHctbXQtNQ h3">
        Servicios en la Nube para LMSs Canvas y Moodle
        </h3><p className="dHctbWF4LXctMnhs dHctbXQtMw dHctdGV4dC1sZw dHctbGVhZGluZy1sb29zZQ dHctdGV4dC1wdXJwbGUtODAw">
        Transforma la experiencia educativa de tu institución con nuestros servicios en la nube para los LMSs Canvas y Moodle. Ofrecemos implementación, gestión y soporte completos para estas plataformas de aprendizaje líderes, asegurando un entorno educativo en línea seguro, escalable y siempre disponible. Nuestra solución facilita la creación de cursos, la gestión de usuarios y la integración de recursos educativos, permitiendo a docentes y estudiantes acceder al aprendizaje desde cualquier lugar y en cualquier momento.</p><div className="dHctbXQtNg"><a className="dHctZm9udC1tZWRpdW0 dHctcmVsYXRpdmU dHctbGVhZGluZy1ub3JtYWw dHctaW5saW5lLWZsZXg dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctcm91bmRlZC1mdWxs dHctb3V0bGluZS1ub25l dHctZ3JvdXA dHctdGV4dC13aGl0ZQ dHctYmctcHVycGxlLTYwMA hover:dHctYmctcHVycGxlLTUwMA dHctcHgtNQ dHctcHktMg.5 dHctdGV4dC1iYXNl" href="/about">Conoce más<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctbWwtMw group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ"><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg></a></div></div></div></div></section>

  );
};

export default Section;
