// src/pages/HomePage.js
import React from 'react';
import WelcomeSection from '../components/WelcomeSection';
import DescriptionSection from '../components/DescriptionSection';
const HomePage = () => {
  return (
       <div>
        <WelcomeSection />
        <DescriptionSection />
        </div>
    
  );
};

export default HomePage;
