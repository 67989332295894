import React from 'react';

const ServiciosProcesosAut = () => {
  return (
    <>
    <section className="dHctcmVsYXRpdmU dHctcHgtNA dHctcHktMTY dHctb3ZlcmZsb3ctaGlkZGVu lg:dHctcHktMjA sm:dHctcHgtNg lg:dHctcHgtOA dHctYmctZ3JhZGllbnQtdG8tYg dHctZnJvbS13aGl0ZQ dHctdmlhLXB1cnBsZS0yNQ dHctdG8td2hpdGU">
    <img alt="" fetchpriority="high" width="300" height="264" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctcmlnaHQtMA dHctaC1hdXRv dHctdHJhbnNmb3Jt dHctb3BhY2l0eS0yNQ 
        dHctdy03Mg lg:dHctb3BhY2l0eS00MA dHctdG9wLTEy" src="/static/media/dots-large-grid.1230c423.svg" style={{ color: 'transparent' }} />
    <img alt="" fetchpriority="high" width="300" height="264" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaC1hdXRv dHctdHJhbnNmb3Jt dHctb3BhY2l0eS00MA LXR3LWxlZnQtMzY 
    dHctYm90dG9tLTM2 dHctdy03Mg" src="/static/media/dots-large-grid.1230c423.svg" style={{ color: 'transparent' }} /><div className="dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctc2NyZWVuLXhs lg:dHctZ3JpZA lg:dHctZ3JpZC1jb2xzLTEy lg:dHctZ2FwLTg"><div className="dHctZmxleA dHctZmxleC1jb2w lg:dHctY29sLXNwYW4tNQ"><div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">
        Automatización de Procesos</span></div><div className="dHctcmVsYXRpdmU"><h1 className="dHctbWF4LXcteGw dHctbXQtNA dHctdGV4dC1wdXJwbGUtOTAw sm:dHctbXQtNQ h1 lg:dHctbWF4LXctbm9uZQ h2">Digitalización y Automatización de Procesos</h1>
    <img alt="" loading="lazy" width="216" height="61" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaGlkZGVu dHctdHJhbnNmb3Jt LXR3LXRvcC0z xl:dHctYmxvY2s md:LXR3LXJpZ2h0LTg 
        dHctdy0xNA md:dHctdy0zMg dHctcm90YXRlLTEy" 
        src="/static/media/arrow-right-over.97d787e9.svg" 
        style={{ color: 'transparent' }} /></div>
        <p className="dHctbWF4LXctMnhs dHctbXQtMw dHctdGV4dC14bA dHctbGVhZGluZy1sb29zZQ dHctdGV4dC1wdXJwbGUtODAw sm:dHctbXQtNA">
        Si necesitas digitalizar o automatizar un proceso de negocio, tenemos la solución perfecta para ti. Contamos con un equipo de expertos en diseño y optimización de procesos, además de desarrolladores con amplia experiencia en Microsoft SharePoint, una combinación esencial para asegurar una digitalización exitosa.
        </p>
            <div className="dHctbXQtMTA dHctZm9udC1zZW1pYm9sZA lg:dHctbXQtMTI">
            <p className="dHctdGV4dC1wdXJwbGUtODAw">¿Agendamos una reunión?</p>
            <a className="dHctZm9udC1tZWRpdW0 dHctcmVsYXRpdmU dHctbGVhZGluZy1ub3JtYWw dHctaW5saW5lLWZsZXg dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ
             dHctcm91bmRlZC1mdWxs dHctb3V0bGluZS1ub25l dHctZ3JvdXA dHctdGV4dC1wdXJwbGUtOTAw dHctYmcteWVsbG93LTUwMA hover:dHctYmcteWVsbG93LTYwMA dHctcHgtNQ dHctcHktMg.5 dHctdGV4dC1iYXNl 
             dHctbXQtMg sm:dHctbXQtMw" href="/contactenos">Contáctenos<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctbWwtMw group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ"><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg></a></div></div><div className="dHctcmVsYXRpdmU dHctbXQtMTI sm:dHctbXQtMTY lg:dHctbXQtMA xl:dHctcGwtMTI lg:dHctY29sLXNwYW4tNw"><div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw sm:dHctZ3JpZA sm:dHctZ2FwLTY md:dHctbWF4LXctM3hs lg:dHctZ2FwLTU lg:dHctbWF4LXctbGc sm:dHctZ3JpZC1jb2xzLTQ lg:dHctZ3JpZC1jb2xzLTI dHctYXV0by1yb3dzLWZy"><a className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctdy1mdWxs dHctcC04 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctc2hhZG93LWxn sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXNwYW4tMQ dHctcm91bmRlZC0yeGw hover:dHctc2NhbGUtMTA1 dHctZ3JvdXA dHctYmctcHVycGxlLTUw" href="#0"><div className="dHctZmxleC0x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy0xMA dHctaC0xMA dHctdGV4dC1wdXJwbGUtNzAw"><path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10"></path><path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2"></path></svg>
            <p className="dHctbXQtNA dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">Digitalización y automatización de procesos</p>
            <p className="dHctbXQtMg dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw">Transformamos y automatizamos tus procesos de negocio para optimizar la eficiencia y reducir los errores.</p>
        </div><div className="dHctZmxleA dHctanVzdGlmeS1lbmQ dHctdy1mdWxs dHctbXQtNg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctdGV4dC1wdXJwbGUtNTAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ hover:dHctdGV4dC1wdXJwbGUtNjAw"><path d="M7 7l5 5l-5 5"></path><path d="M13 7l5 5l-5 5"></path></svg></div></a><a className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctdy1mdWxs dHctcC04 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctc2hhZG93LWxn sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXNwYW4tMQ dHctcm91bmRlZC0yeGw hover:dHctc2NhbGUtMTA1 dHctZ3JvdXA dHctbXQtNg sm:dHctbXQtMA lg:dHctdHJhbnNsYXRlLXktMjg dHctYmcteWVsbG93LTIwMA" href="#0"><div className="dHctZmxleC0x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy0xMA dHctaC0xMA dHctdGV4dC1wdXJwbGUtNzAw"><path d="M3.5 5.5l1.5 1.5l2.5 -2.5"></path><path d="M3.5 11.5l1.5 1.5l2.5 -2.5"></path><path d="M3.5 17.5l1.5 1.5l2.5 -2.5"></path><path d="M11 6l9 0"></path><path d="M11 12l9 0"></path><path d="M11 18l9 0"></path></svg><p className="dHctbXQtNA dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">
            Digitalización de documentos físicos</p>
            <p className="dHctbXQtMg dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw">
                    Convertimos tus documentos en  formatos digitales, facilitando su acceso, constulta y gestión.
            </p></div><div className="dHctZmxleA dHctanVzdGlmeS1lbmQ dHctdy1mdWxs dHctbXQtNg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                    fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctdGV4dC1wdXJwbGUtNTAw 
                    dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ hover:dHctdGV4dC1wdXJwbGUtNjAw">
                        <path d="M7 7l5 5l-5 5"></path><path d="M13 7l5 5l-5 5"></path></svg></div></a>
                        <a className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctdy1mdWxs dHctcC04 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctc2hhZG93LWxn 
                        sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXNwYW4tMQ dHctcm91bmRlZC0yeGw hover:dHctc2NhbGUtMTA1 dHctZ3JvdXA dHctbXQtNg sm:dHctbXQtMA dHctYmctcm9zZS01MA" 
                        href="#0">
                            <div className="dHctZmxleC0x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" 
                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy0xMA dHctaC0xMA dHctdGV4dC1wdXJwbGUtNzAw"><path d="M13.62 8.382l1.966 -1.967a2 2 0 1 1 3.414 -1.415a2 2 0 1 1 -1.413 3.414l-1.82 1.821"></path><path d="M5.904 18.596c2.733 2.734 5.9 4 7.07 2.829c1.172 -1.172 -.094 -4.338 -2.828 -7.071c-2.733 -2.734 -5.9 -4 -7.07 -2.829c-1.172 1.172 .094 4.338 2.828 7.071z"></path><path d="M7.5 16l1 1"></path><path d="M12.975 21.425c3.905 -3.906 4.855 -9.288 2.121 -12.021c-2.733 -2.734 -8.115 -1.784 -12.02 2.121"></path></svg>
                            <p className="dHctbXQtNA dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">Desarrollo a medida para front-end y SharePoint</p>
                            <p className="dHctbXQtMg dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw">Desarrollamos soluciones personalizadas tanto para interfaces de usuario como para la plataforma SharePoint.</p></div><div className="dHctZmxleA dHctanVzdGlmeS1lbmQ dHctdy1mdWxs dHctbXQtNg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctdGV4dC1wdXJwbGUtNTAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ hover:dHctdGV4dC1wdXJwbGUtNjAw"><path d="M7 7l5 5l-5 5"></path><path d="M13 7l5 5l-5 5"></path></svg></div></a><a className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctdy1mdWxs dHctcC04 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctc2hhZG93LWxn sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXNwYW4tMQ dHctcm91bmRlZC0yeGw hover:dHctc2NhbGUtMTA1 dHctZ3JvdXA dHctbXQtNg sm:dHctbXQtMA lg:dHctdHJhbnNsYXRlLXktMjg dHctYmctdGVhbC01MA" href="#0"><div className="dHctZmxleC0x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy0xMA dHctaC0xMA dHctdGV4dC1wdXJwbGUtNzAw"><path d="M12 12m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0"></path><path d="M12 7v-4"></path><path d="M11 3h2"></path><path d="M15.536 8.464l2.828 -2.828"></path><path d="M17.657 4.929l1.414 1.414"></path><path d="M17 12h4"></path><path d="M21 11v2"></path><path d="M15.535 15.536l2.829 2.828"></path><path d="M19.071 17.657l-1.414 1.414"></path><path d="M12 17v4"></path><path d="M13 21h-2"></path><path d="M8.465 15.536l-2.829 2.828"></path><path d="M6.343 19.071l-1.413 -1.414"></path><path d="M7 12h-4"></path><path d="M3 13v-2"></path><path d="M8.464 8.464l-2.828 -2.828"></path><path d="M4.929 6.343l1.414 -1.413"></path></svg>
                            <p className="dHctbXQtNA dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">Gestión y estructura documental</p>
                            <p className="dHctbXQtMg dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw">Organizamos y estructuramos tus documentos de manera eficiente para mejorar la accesibilidad y el control.</p></div>
                            <div className="dHctZmxleA dHctanVzdGlmeS1lbmQ dHctdy1mdWxs dHctbXQtNg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctdGV4dC1wdXJwbGUtNTAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ hover:dHctdGV4dC1wdXJwbGUtNjAw"><path d="M7 7l5 5l-5 5"></path><path d="M13 7l5 5l-5 5"></path></svg></div></a><a className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctdy1mdWxs dHctcC04 dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctdHJhbnNmb3Jt dHctc2hhZG93LWxn sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXNwYW4tMQ dHctcm91bmRlZC0yeGw hover:dHctc2NhbGUtMTA1 dHctZ3JvdXA dHctbXQtNg sm:dHctbXQtMA sm:dHctY29sLXN0YXJ0LTI dHctYmctYmx1ZS01MA" href="/"><div className="dHctZmxleC0x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy0xMA dHctaC0xMA dHctdGV4dC1wdXJwbGUtNzAw"><path d="M15 8h.01"></path><path d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z"></path><path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5"></path><path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3"></path></svg>
                            <p className="dHctbXQtNA dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">Diseño y consultoría de procesos</p>
                            <p className="dHctbXQtMg dHctdGV4dC1zbQ dHctdGV4dC1wdXJwbGUtODAw">Ofrecemos servicios de diseño y consultoría para optimizar y mejorar tus procesos de negocio.</p></div><div className="dHctZmxleA dHctanVzdGlmeS1lbmQ dHctdy1mdWxs dHctbXQtNg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctdGV4dC1wdXJwbGUtNTAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ hover:dHctdGV4dC1wdXJwbGUtNjAw"><path d="M7 7l5 5l-5 5"></path><path d="M13 7l5 5l-5 5"></path></svg></div></a></div></div></div>
        </section>

   
   <section className="dHctcHgtNA dHctb3ZlcmZsb3ctaGlkZGVu dHctYmctd2hpdGU dHctcGItMjg sm:dHctcGItMzY sm:dHctcHgtNg lg:dHctcHgtOA">
        <div className="dHctbWF4LXctc2NyZWVuLXhs dHctbXgtYXV0bw"><div className="dHctcHgtNA dHctdGV4dC1jZW50ZXI sm:dHctcHgtNg lg:dHctcHgtOA"><h3 className="dHctdGV4dC1wdXJwbGUtOTAw h2"><span className="dHctYmxvY2s">
            Digitalización, Automatización</span><span className="dHctcmVsYXRpdmU dHctYmxvY2s"><span className="dHctcmVsYXRpdmU">
            <img alt="" loading="lazy" width="1126" height="64" decoding="async" data-nimg="1" 
                        className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdHJhbnNmb3Jt dHctdHJhbnNsYXRlLXktOQ sm:dHctdHJhbnNsYXRlLXktMTA 
                                        xl:dHctdHJhbnNsYXRlLXktMTI" 
                        src="/_next/static/media/underline-simple-light-purple.ff6e5b29.svg" style={{ color: 'transparent' }} /><span className="dHctcmVsYXRpdmU">RPA, Transformación Digital</span></span></span></h3></div>
                        
 
 
 
 
 
<div className="dHctZ3JpZA dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMjA lg:dHctbWF4LXctbm9uZQ sm:dHctbXQtMjQ lg:dHctbXQtNDQ lg:dHctZ3JpZC1jb2xzLTEy dHctZ2FwLTE0 sm:dHctZ2FwLTE2 lg:dHctZ2FwLTg"><div className="dHctcmVsYXRpdmU dHctei0xMA dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctb3JkZXItMg lg:dHctY29sLXNwYW4tNg lg:dHctdGV4dC1sZWZ0"><div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">Datos del CLiente</span></div><div><h1 className="dHctbXQtMw.5 dHctZm9udC1ib2xk dHctdGV4dC1wdXJwbGUtOTAw h3">
            Automatización Empresarial con RPA Integrada y Flujos de Trabajo Personalizados.</h1>
                <p className="dHctbWF4LXcteGw dHctbXQtMw dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw sm:dHctbGVhZGluZy1yZWxheGVk sm:dHctdGV4dC14bA">
                Automatiza tus procesos empresariales con RPA integrada usando Microsoft Power Automate y Google Workspace. Diseña flujos de trabajo personalizados que conectan aplicaciones y sistemas, eliminando tareas repetitivas y reduciendo errores. Gestiona y optimiza todo desde un panel centralizado, permitiendo a tu equipo enfocarse en actividades estratégicas mientras la tecnología se encarga del resto.                </p></div></div><div className="dHctcmVsYXRpdmU dHctb3JkZXItMQ dHctdy1mdWxs dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctbm9uZQ lg:dHctbXgtMA lg:dHctY29sLXNwYW4tNg lg:dHctZmxleA lg:dHctaXRlbXMtY2VudGVy">
                    <div className="dHctaGlkZGVu lg:dHctYmxvY2s">
            <img alt="" loading="lazy" width="433" height="403" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdy1mdWxs dHctaC1mdWxs dHctdHJhbnNmb3Jt lg:dHctc2NhbGUtMTM1" src="/static/media/blob-light-purple.fea8cd27.svg" style={{ color: 'transparent' }}
            /></div>
            <img alt="" loading="lazy" width="300" height="538" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctdG9wLTA dHctb3JpZ2luLXRvcA dHctdHJhbnNmb3Jt LXR3LXRyYW5zbGF0ZS14LTEvMg LXR3LXRyYW5zbGF0ZS15LTg dHctbGVmdC0xLzI lg:dHctaGlkZGVu sm:dHctc2NhbGUtMTAw dHctc2NhbGUtODA" src="/static/media/dots-large-strip.80b8337d.svg" 
                    style={{ color: 'transparent' }} /><div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw dHctc2hhZG93LWxn dHctcm91bmRlZC0zeGw lg:dHctbWF4LXctbGc lg:dHctbXgtMA"><div className="dHctcmVsYXRpdmU dHctYmxvY2s dHctdy1mdWxs">
            <img alt="" loading="lazy" width="600" height="511" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctei0xMA dHctaGlkZGVu dHctdy00MA dHctdHJhbnNmb3Jt lg:dHctYmxvY2s LXR3LXRvcC0yMA xl:dHctdy00OA xl:LXR3LXRvcC0yMA LXR3LXJpZ2h0LTIw" src="/static/media/dots.80e6656f.svg" style={{ color: 'transparent' }} />
            <figure className="dHctcmVsYXRpdmU dHctYXNwZWN0LQ[12/10] md:dHctb3JkZXItMQ">
            <img alt="Child painting" loading="lazy" decoding="async" data-nimg="fill" 
            className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctb2JqZWN0LWNlbnRlcg dHctdy1mdWxs dHctaC1mdWxs dHctc2hhZG93LXhs dHctcm91bmRlZC0zeGw" 
            sizes="(min-width: 1024px) 32rem, (min-width: 576px) 36rem, 100vw" 
            src="/static/media/images/selective-focus-of-human-and-robot-hands-typing-on-2023-11-27-05-28-22-utc.jpg" 
            style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }}
            /></figure></div></div></div>
            
</div>
 
                        <div className="dHctZ3JpZA dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMjA lg:dHctbWF4LXctbm9uZQ sm:dHctbXQtMjQ lg:dHctbXQtNDQ lg:dHctZ3JpZC1jb2xzLTEy 
    dHctZ2FwLTE0 sm:dHctZ2FwLTE2 lg:dHctZ2FwLTg">
        <div className="dHctcmVsYXRpdmU dHctei0xMA dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctb3JkZXItMg lg:dHctY29sLXNwYW4tNg 
        lg:dHctdGV4dC1sZWZ0 lg:dHctb3JkZXItMQ"><div>
            <span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA
             dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">
        100% en la Nube</span></div><div>
                                            <h1 className="dHctbXQtMw.5 dHctZm9udC1ib2xk dHctdGV4dC1wdXJwbGUtOTAw h3">
                                                Solución 100% cloud con 1 TB de espacio inicial disponible.
                                            </h1>
                                            <p className="dHctbWF4LXcteGw dHctbXQtMw dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw sm:dHctbGVhZGluZy1yZWxheGVk sm:dHctdGV4dC14bA">
                                            Nuestra solución se basa en la nube, lo que significa que todos los datos y aplicaciones están alojados en servidores remotos accesibles desde cualquier lugar con una conexión a Internet. Ofrecemos un terabyte (1 TB) de espacio inicial, lo que proporciona una amplia capacidad de almacenamiento para tus datos y documentos empresariales. Esto no solo elimina la necesidad de mantener y actualizar hardware físico, sino que también ofrece la flexibilidad de escalar el almacenamiento según las necesidades de tu empresa.
                                        </p></div></div>
                    <div className="dHctcmVsYXRpdmU dHctb3JkZXItMQ dHctdy1mdWxs dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctbm9uZQ lg:dHctbXgtMA lg:dHctY29sLXNwYW4tNg 
                    lg:dHctZmxleA lg:dHctaXRlbXMtY2VudGVy lg:dHctb3JkZXItMg">
                <div className="dHctaGlkZGVu lg:dHctYmxvY2s">
                            <img alt="" loading="lazy" width="433" height="403" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdy1mdWxs dHctaC1mdWxs dHctdHJhbnNmb3Jt 
                            lg:dHctc2NhbGUtMTM1" src="/static/media/blob-light-yellow.f847cd46.svg" style={{ color: 'transparent' }} /></div>
                    <img alt="" loading="lazy" width="300" height="538" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctdG9wLTA dHctb3JpZ2luLXRvcA 
                        dHctdHJhbnNmb3Jt LXR3LXRyYW5zbGF0ZS14LTEvMg LXR3LXRyYW5zbGF0ZS15LTg dHctbGVmdC0xLzI lg:dHctaGlkZGVu sm:dHctc2NhbGUtMTAw dHctc2NhbGUtODA" 
                        src="/static/media/dots-large-strip.80b8337d.svg" style={{ color: 'transparent' }} /><div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw dHctc2hhZG93LWxn dHctcm91bmRlZC0zeGw lg:dHctbWF4LXctbGc lg:dHctbXItMA lg:dHctbWwtYXV0bw"><div className="dHctcmVsYXRpdmU dHctYmxvY2s dHctdy1mdWxs">
        
        
            <img alt="" loading="lazy" width="600" height="511" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctei0xMA dHctaGlkZGVu dHctdy00MA dHctdHJhbnNmb3Jt lg:dHctYmxvY2s 
                            LXR3LXRvcC0yMA xl:dHctdy00OA xl:LXR3LXRvcC0yMA LXR3LWxlZnQtMjA" src="/static/media/dots.80e6656f.svg" style={{ color: 'transparent' }} />
                            <figure className="dHctcmVsYXRpdmU dHctYXNwZWN0LQ[12/10] md:dHctb3JkZXItMQ">
                         <img alt="Child covered in paint" loading="lazy" decoding="async" data-nimg="fill" 
                         className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctb2JqZWN0LWNlbnRlcg dHctdy1mdWxs 
                    dHctaC1mdWxs dHctc2hhZG93LXhs dHctcm91bmRlZC0zeGw" sizes="(min-width: 1024px) 32rem, (min-width: 576px) 36rem, 100vw" 
                     src="/static/media/images/opened-hard-disk-after-disassembly-during-repair-o-2023-11-27-04-53-01-utc.jpg" 
                    style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }}/></figure></div></div></div>


</div>

<div className="dHctZ3JpZA dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMjA lg:dHctbWF4LXctbm9uZQ sm:dHctbXQtMjQ lg:dHctbXQtNDQ lg:dHctZ3JpZC1jb2xzLTEy dHctZ2FwLTE0 sm:dHctZ2FwLTE2 lg:dHctZ2FwLTg"><div className="dHctcmVsYXRpdmU dHctei0xMA dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctb3JkZXItMg lg:dHctY29sLXNwYW4tNg lg:dHctdGV4dC1sZWZ0"><div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">Datos del CLiente</span></div><div><h1 className="dHctbXQtMw.5 dHctZm9udC1ib2xk dHctdGV4dC1wdXJwbGUtOTAw h3">
                        Toda la BD de información y documentos es siempre del cliente.</h1>
                <p className="dHctbWF4LXcteGw dHctbXQtMw dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw sm:dHctbGVhZGluZy1yZWxheGVk sm:dHctdGV4dC14bA">
                A diferencia de otros proveedores que pueden reclamar propiedad o control sobre los datos almacenados en sus sistemas, con nosotros, tú siempre serás el propietario absoluto de tu base de datos y documentos. Esto significa que tienes control total sobre tu información, con la seguridad de que nunca se utilizará sin tu consentimiento. Además, ofrecemos opciones avanzadas de respaldo y recuperación para garantizar que tus datos estén siempre seguros y disponibles cuando los necesites.</p></div></div><div className="dHctcmVsYXRpdmU dHctb3JkZXItMQ dHctdy1mdWxs dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctbm9uZQ lg:dHctbXgtMA lg:dHctY29sLXNwYW4tNg lg:dHctZmxleA lg:dHctaXRlbXMtY2VudGVy"><div className="dHctaGlkZGVu lg:dHctYmxvY2s">
            <img alt="" loading="lazy" width="433" height="403" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdy1mdWxs dHctaC1mdWxs dHctdHJhbnNmb3Jt lg:dHctc2NhbGUtMTM1" src="/static/media/blob-light-purple.fea8cd27.svg" style={{ color: 'transparent' }}
            /></div>
            <img alt="" loading="lazy" width="300" height="538" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctdG9wLTA dHctb3JpZ2luLXRvcA dHctdHJhbnNmb3Jt LXR3LXRyYW5zbGF0ZS14LTEvMg LXR3LXRyYW5zbGF0ZS15LTg dHctbGVmdC0xLzI lg:dHctaGlkZGVu sm:dHctc2NhbGUtMTAw dHctc2NhbGUtODA" src="/static/media/dots-large-strip.80b8337d.svg" 
                    style={{ color: 'transparent' }} /><div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw dHctc2hhZG93LWxn dHctcm91bmRlZC0zeGw lg:dHctbWF4LXctbGc lg:dHctbXgtMA"><div className="dHctcmVsYXRpdmU dHctYmxvY2s dHctdy1mdWxs">
            <img alt="" loading="lazy" width="600" height="511" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctei0xMA dHctaGlkZGVu dHctdy00MA dHctdHJhbnNmb3Jt lg:dHctYmxvY2s LXR3LXRvcC0yMA xl:dHctdy00OA xl:LXR3LXRvcC0yMA LXR3LXJpZ2h0LTIw" src="/static/media/dots.80e6656f.svg" style={{ color: 'transparent' }} />
            <figure className="dHctcmVsYXRpdmU dHctYXNwZWN0LQ[12/10] md:dHctb3JkZXItMQ">
            <img alt="Child painting" loading="lazy" decoding="async" data-nimg="fill" 
            className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctb2JqZWN0LWNlbnRlcg dHctdy1mdWxs dHctaC1mdWxs dHctc2hhZG93LXhs dHctcm91bmRlZC0zeGw" 
            sizes="(min-width: 1024px) 32rem, (min-width: 576px) 36rem, 100vw" 
            src="/static/media/images/mature-multi-ethnic-couple-happy-and-in-love-while-2023-11-27-04-55-15-utc.jpg" 
            style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }}
            /></figure></div></div></div>
            
</div>

<div className="dHctZ3JpZA dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMjA lg:dHctbWF4LXctbm9uZQ sm:dHctbXQtMjQ lg:dHctbXQtNDQ lg:dHctZ3JpZC1jb2xzLTEy dHctZ2FwLTE0 sm:dHctZ2FwLTE2 lg:dHctZ2FwLTg"><div className="dHctcmVsYXRpdmU dHctei0xMA dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctb3JkZXItMg lg:dHctY29sLXNwYW4tNg lg:dHctdGV4dC1sZWZ0 lg:dHctb3JkZXItMQ"><div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">Seguridad</span></div><div><h1 className="dHctbXQtMw.5 dHctZm9udC1ib2xk dHctdGV4dC1wdXJwbGUtOTAw h3">
                No se necesita especialistas para Administración y Gestión de la Seguridad.</h1>
                <p className="dHctbWF4LXcteGw dHctbXQtMw dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw sm:dHctbGVhZGluZy1yZWxheGVk sm:dHctdGV4dC14bA">
                Nuestra solución está diseñada para ser fácil de usar, lo que significa que no necesitarás contratar personal especializado para administrar o gestionar la seguridad de tus sistemas. Hemos integrado medidas de seguridad avanzadas que funcionan en segundo plano, protegiendo tus datos de amenazas y accesos no autorizados. Esto incluye encriptación, firewalls, y monitoreo constante, todo gestionado por nuestro equipo de expertos, para que puedas centrarte en tu negocio en lugar de en la seguridad informática.</p></div></div><div className="dHctcmVsYXRpdmU dHctb3JkZXItMQ dHctdy1mdWxs dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctbm9uZQ lg:dHctbXgtMA lg:dHctY29sLXNwYW4tNg lg:dHctZmxleA lg:dHctaXRlbXMtY2VudGVy lg:dHctb3JkZXItMg"><div className="dHctaGlkZGVu lg:dHctYmxvY2s">
            <img alt="" loading="lazy" width="398" height="374" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdy1mdWxs dHctaC1mdWxs dHctdHJhbnNmb3Jt lg:dHctc2NhbGUtMTM1" 
            src="/static/media/blob-light-rose.49747274.svg" style={{ color: 'transparent' }} /></div>
            <img alt="" 
                loading="lazy" width="300" height="538" decoding="async" data-nimg="1" 
                className="dHctYWJzb2x1dGU dHctdG9wLTA dHctb3JpZ2luLXRvcA dHctdHJhbnNmb3Jt LXR3LXRyYW5zbGF0ZS14LTEvMg LXR3LXRyYW5zbGF0ZS15LTg dHctbGVmdC0xLzI 
                        lg:dHctaGlkZGVu sm:dHctc2NhbGUtMTAw dHctc2NhbGUtODA" 
                src="/static/media/dots-large-strip.80b8337d.svg" 
                style={{ color: 'transparent' }} />
                <div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw dHctc2hhZG93LWxn dHctcm91bmRlZC0zeGw lg:dHctbWF4LXctbGc lg:dHctbXItMA lg:dHctbWwtYXV0bw"><div className="dHctcmVsYXRpdmU dHctYmxvY2s dHctdy1mdWxs">
            <img alt="" loading="lazy" width="600" height="511" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctei0xMA dHctaGlkZGVu dHctdy00MA dHctdHJhbnNmb3Jt lg:dHctYmxvY2s 
                        LXR3LXRvcC0yMA xl:dHctdy00OA xl:LXR3LXRvcC0yMA LXR3LWxlZnQtMjA" src="/static/media/dots.80e6656f.svg" style={{ color: 'transparent' }} />
                        <figure className="dHctcmVsYXRpdmU dHctYXNwZWN0LQ[12/10] md:dHctb3JkZXItMQ">
                            <img alt="Child working on workbook" loading="lazy" decoding="async" data-nimg="fill" 
                            className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctb2JqZWN0LWNlbnRlcg dHctdy1mdWxs 
                            dHctaC1mdWxs dHctc2hhZG93LXhs dHctcm91bmRlZC0zeGw" sizes="(min-width: 1024px) 32rem, (min-width: 576px) 36rem, 100vw" 
                            src="/static/media/images/cool-calm-and-casual-studio-shot-of-a-diverse-gr-2023-11-27-05-25-53-utc.jpg" 
                            style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }}
                         /></figure></div></div></div></div>
                         
                    <div className="dHctZ3JpZA dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMjA lg:dHctbWF4LXctbm9uZQ sm:dHctbXQtMjQ lg:dHctbXQtNDQ 
                         lg:dHctZ3JpZC1jb2xzLTEy dHctZ2FwLTE0 sm:dHctZ2FwLTE2 lg:dHctZ2FwLTg">
                            <div className="dHctcmVsYXRpdmU dHctei0xMA dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctb3JkZXItMg lg:dHctY29sLXNwYW4tNg lg:dHctdGV4dC1sZWZ0">
                                <div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">
                                    Cero Infraestructura</span></div><div><h1 className="dHctbXQtMw.5 dHctZm9udC1ib2xk dHctdGV4dC1wdXJwbGUtOTAw h3">
                         No es necesario invertir en infraestructura de ningún tipo.</h1>
                <p className="dHctbWF4LXcteGw dHctbXQtMw dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw sm:dHctbGVhZGluZy1yZWxheGVk sm:dHctdGV4dC14bA">
                Con nuestra solución cloud, eliminamos la necesidad de invertir en infraestructura física como servidores, redes, o centros de datos. Todo está alojado en la nube, lo que no solo reduce los costos iniciales, sino que también disminuye los gastos operativos a largo plazo. No tendrás que preocuparte por el mantenimiento o la actualización de hardware, lo que libera recursos financieros y humanos que pueden ser redirigidos a otras áreas críticas de tu negocio.
                </p></div></div><div className="dHctcmVsYXRpdmU dHctb3JkZXItMQ dHctdy1mdWxs dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctbm9uZQ lg:dHctbXgtMA lg:dHctY29sLXNwYW4tNg lg:dHctZmxleA lg:dHctaXRlbXMtY2VudGVy"><div className="dHctaGlkZGVu lg:dHctYmxvY2s">
            <img alt="" loading="lazy" width="433" height="403" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdy1mdWxs dHctaC1mdWxs dHctdHJhbnNmb3Jt lg:dHctc2NhbGUtMTM1" 
            src="/static/media/blob-light-purple.fea8cd27.svg" style={{ color: 'transparent' }}
            /></div>
            <img alt="" loading="lazy" width="300" height="538" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctdG9wLTA dHctb3JpZ2luLXRvcA dHctdHJhbnNmb3Jt LXR3LXRyYW5zbGF0ZS14LTEvMg LXR3LXRyYW5zbGF0ZS15LTg dHctbGVmdC0xLzI lg:dHctaGlkZGVu sm:dHctc2NhbGUtMTAw dHctc2NhbGUtODA" src="/static/media/dots-large-strip.80b8337d.svg" 
                    style={{ color: 'transparent' }} /><div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw dHctc2hhZG93LWxn dHctcm91bmRlZC0zeGw lg:dHctbWF4LXctbGc lg:dHctbXgtMA"><div className="dHctcmVsYXRpdmU dHctYmxvY2s dHctdy1mdWxs">
            <img alt="" loading="lazy" width="600" height="511" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctei0xMA dHctaGlkZGVu dHctdy00MA dHctdHJhbnNmb3Jt lg:dHctYmxvY2s LXR3LXRvcC0yMA xl:dHctdy00OA xl:LXR3LXRvcC0yMA LXR3LXJpZ2h0LTIw" src="/static/media/dots.80e6656f.svg" style={{ color: 'transparent' }} />
            <figure className="dHctcmVsYXRpdmU dHctYXNwZWN0LQ[12/10] md:dHctb3JkZXItMQ">
            <img alt="Child painting" loading="lazy" decoding="async" data-nimg="fill" 
            className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctb2JqZWN0LWNlbnRlcg dHctdy1mdWxs dHctaC1mdWxs dHctc2hhZG93LXhs dHctcm91bmRlZC0zeGw" 
            sizes="(min-width: 1024px) 32rem, (min-width: 576px) 36rem, 100vw" 
            src="/static/media/images/clouds-and-beautiful-clear-blue-sky-in-summer-2023-11-27-05-23-24-utc.jpg" 
            style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }}
            /></figure></div></div></div>
            
</div>

<div className="dHctZ3JpZA dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMjA lg:dHctbWF4LXctbm9uZQ sm:dHctbXQtMjQ lg:dHctbXQtNDQ lg:dHctZ3JpZC1jb2xzLTEy dHctZ2FwLTE0 sm:dHctZ2FwLTE2 lg:dHctZ2FwLTg"><div className="dHctcmVsYXRpdmU dHctei0xMA dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctb3JkZXItMg lg:dHctY29sLXNwYW4tNg lg:dHctdGV4dC1sZWZ0 lg:dHctb3JkZXItMQ"><div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">100% en la Nube</span></div><div>
                                            <h1 className="dHctbXQtMw.5 dHctZm9udC1ib2xk dHctdGV4dC1wdXJwbGUtOTAw h3">
                                            La solución se puede arrendar en un SaaS a 12, 24 o 36 meses.
                                            </h1>
                                            <p className="dHctbWF4LXcteGw dHctbXQtMw dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw sm:dHctbGVhZGluZy1yZWxheGVk sm:dHctdGV4dC14bA">
                                            Ofrecemos nuestra solución en un modelo de Software como Servicio (SaaS), con opciones de arrendamiento flexible a 12, 24 o 36 meses. Esto te permite adaptar los costos de la solución a las necesidades de tu empresa, brindándote la capacidad de acceder a tecnología avanzada sin un gran desembolso inicial. Además, este modelo facilita las actualizaciones continuas y el soporte técnico, asegurando que siempre estés utilizando la versión más reciente y segura de nuestro software
                                        </p></div></div>
                    <div className="dHctcmVsYXRpdmU dHctb3JkZXItMQ dHctdy1mdWxs dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctbm9uZQ lg:dHctbXgtMA lg:dHctY29sLXNwYW4tNg 
                    lg:dHctZmxleA lg:dHctaXRlbXMtY2VudGVy lg:dHctb3JkZXItMg">
                <div className="dHctaGlkZGVu lg:dHctYmxvY2s">
                            <img alt="" loading="lazy" width="433" height="403" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctdy1mdWxs dHctaC1mdWxs dHctdHJhbnNmb3Jt 
                            lg:dHctc2NhbGUtMTM1" src="/static/media/blob-light-yellow.f847cd46.svg" style={{ color: 'transparent' }} /></div>
                    <img alt="" loading="lazy" width="300" height="538" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctdG9wLTA dHctb3JpZ2luLXRvcA 
                        dHctdHJhbnNmb3Jt LXR3LXRyYW5zbGF0ZS14LTEvMg LXR3LXRyYW5zbGF0ZS15LTg dHctbGVmdC0xLzI lg:dHctaGlkZGVu sm:dHctc2NhbGUtMTAw dHctc2NhbGUtODA" 
                        src="/static/media/dots-large-strip.80b8337d.svg" style={{ color: 'transparent' }} /><div className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctbXgtYXV0bw dHctc2hhZG93LWxn dHctcm91bmRlZC0zeGw lg:dHctbWF4LXctbGc lg:dHctbXItMA lg:dHctbWwtYXV0bw"><div className="dHctcmVsYXRpdmU dHctYmxvY2s dHctdy1mdWxs">
            <img alt="" loading="lazy" width="600" height="511" decoding="async" data-nimg="1" className="dHctYWJzb2x1dGU dHctei0xMA dHctaGlkZGVu dHctdy00MA dHctdHJhbnNmb3Jt lg:dHctYmxvY2s 
                            LXR3LXRvcC0yMA xl:dHctdy00OA xl:LXR3LXRvcC0yMA LXR3LWxlZnQtMjA" src="/static/media/dots.80e6656f.svg" style={{ color: 'transparent' }} />
                            <figure className="dHctcmVsYXRpdmU dHctYXNwZWN0LQ[12/10] md:dHctb3JkZXItMQ">
                         <img alt="Child covered in paint" loading="lazy" decoding="async" data-nimg="fill" 
                         className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctb2JqZWN0LWNlbnRlcg dHctdy1mdWxs 
                    dHctaC1mdWxs dHctc2hhZG93LXhs dHctcm91bmRlZC0zeGw" sizes="(min-width: 1024px) 32rem, (min-width: 576px) 36rem, 100vw" 
                     src="/static/media/images/cloud-computing-concept-2023-11-27-05-27-06-utc.jpg" 
                    style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }}/></figure></div></div></div>
</div>
                         
                         </div></section>

                         <section className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctcHgtNA dHctcHktMTY sm:dHctcHktMjQ sm:dHctcHgtNg lg:dHctcHgtOA">
        <div className="dHctbWF4LXctMnhs dHctbXgtYXV0bw lg:dHctbWF4LXctc2NyZWVuLXhs">
            <div>
            <h2 className="dHctdGV4dC1wdXJwbGUtOTAw h2 lg:dHctdGV4dC1jZW50ZXI">Comienza ahora tu transformación digital</h2>
            <p className="dHctbWF4LXctMnhs dHctbXgtYXV0bw dHctbXQtMw dHctdGV4dC14bA dHctbGVhZGluZy1yZWxheGVk dHctdGV4dC1wdXJwbGUtODAw lg:dHctbXQtNA lg:dHctdGV4dC1jZW50ZXI">
            La transformación digital es un proceso vital para cualquier empresa que busca mantenerse competitiva en el entorno actual. Nuestro enfoque se centra en guiarte en cada paso de este viaje, asegurando que tu empresa no solo adopte las nuevas tecnologías, sino que también las integre de manera efectiva para mejorar sus procesos, aumentar la eficiencia y generar un valor tangible. Desde la planificación estratégica hasta la implementación y el soporte continuo, estamos comprometidos en acompañarte a lo largo de todo el proceso, ayudando a tu empresa a adaptarse y prosperar en el mundo digital.
            </p>
            <p className="dHctbWF4LXctMnhs dHctbXgtYXV0bw dHctbXQtMw dHctdGV4dC14bA dHctbGVhZGluZy1yZWxheGVk dHctdGV4dC1wdXJwbGUtODAw lg:dHctbXQtNA lg:dHctdGV4dC1jZW50ZXI">
                Nuestro modelo de negocio está diseñado para proporcionar beneficios inmediatos a tu empresa desde el primer día. Al ofrecer soluciones personalizadas y un soporte continuo, nos aseguramos de que tu inversión en tecnología comience a rendir frutos de manera inmediata. Nos enfocamos en optimizar los procesos internos, mejorar la comunicación y colaboración entre equipos, y reducir los costos operativos. Todo esto se traduce en un impacto positivo y directo en la productividad y rentabilidad de tu empresa.
                </p>
            </div>
            <div className="dHctbXQtMTI lg:dHctZ3JpZA lg:dHctZ3JpZC1jb2xzLTI lg:dHctZ2FwLTY xl:dHctZ2FwLTg sm:dHctbXQtMTY">
    
            </div>
        </div>
        </section>
            </>
  );
}

export default ServiciosProcesosAut;
