
import React from 'react';

const ContactInformation = () => {
  return (
        <section className="dHctcmVsYXRpdmU dHctdy1mdWxs dHctcHgtNA dHctcHQtNTY dHctYmctd2hpdGU LXR3LW1iLTUy LXR3LXRyYW5zbGF0ZS15LTUy sm:dHctcHQtNjQ lg:dHctcHQtNzI sm:dHctcHgtNg lg:dHctcHgtOA">
            <div className="dHctbWF4LXcteGw dHctbXgtYXV0bw lg:dHctbWF4LXctc2NyZWVuLXhs"><div className="lg:dHctZ3JpZA lg:dHctZ3JpZC1jb2xzLTI lg:dHctZ2FwLTE2 xl:dHctZ2FwLTMy"><div className="dHctZmxleA dHctaXRlbXMtY2VudGVy">
                <h2 className="dHctbWF4LXctNHhs dHctdGV4dC1wdXJwbGUtOTAw h2">Información de Contacto</h2></div>
            <div className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXQtMw sm:dHctbXQtNA lg:dHctbXQtMA">
                <p className="dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw sm:dHctdGV4dC14bA dHctdGV4dC1vcGFjaXR5LTkw">Alternativamente contáctenos por otros canales que hemos habilitado.</p></div></div><div className="dHctZ3JpZA dHctZ3JpZC1jb2xzLTE dHctZ2FwLTQ dHctbXQtMTI sm:dHctbXQtMTQ lg:dHctbXQtMjA sm:dHctZ3JpZC1jb2xzLTQ lg:dHctZ3JpZC1jb2xzLTM sm:dHctZ2FwLTY xl:dHctZ2FwLTEy"><div className="dHctcHgtNA dHctcHktOA dHctYmcteWVsbG93LTIwMA sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXNwYW4tMQ sm:dHctcC04 dHctcm91bmRlZC0zeGw"><div className="dHctZmxleA sm:dHctZmxleC1jb2w lg:dHctZmxleC1yb3c"><div><span className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctYmcteWVsbG93LTQwMA dHctcm91bmRlZC0yeGw dHctdy0xNA dHctaC0xNA"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy04 dHctaC04 dHctdGV4dC1wdXJwbGUtNzAw"><path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path><path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path></svg></span></div><div className="dHctZmxleC0x dHctbWwtNg sm:dHctbXQtMw lg:dHctbXQtMA sm:dHctbWwtMA lg:dHctbWwtNg">
                    <h5 className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">Chat</h5>
                    <p className="dHctbXQtMQ.5 dHctdGV4dC1iYXNl dHctdGV4dC1wdXJwbGUtODAw dHctbGVhZGluZy1yZWxheGVk">
                        Conversémos por Whatsapp
                    </p></div></div></div>
                    <div className="dHctcHgtNA dHctcHktOA sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXNwYW4tMQ sm:dHctcC04 sm:dHctcHktMTA dHctcm91bmRlZC0zeGw dHctYmctcHVycGxlLTUw">
                        <div className="dHctZmxleA sm:dHctZmxleC1jb2w lg:dHctZmxleC1yb3c"><div>
                            <span className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC0yeGw dHctdy0xNA dHctaC0xNA"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy04 dHctaC04 dHctdGV4dC1wdXJwbGUtNzAw"><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"></path><path d="M3 7l9 6l9 -6"></path></svg></span></div><div className="dHctZmxleC0x dHctbWwtNg sm:dHctbXQtMw lg:dHctbXQtMA sm:dHctbWwtMA lg:dHctbWwtNg">
                    <h5 className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">Email</h5><p className="dHctbXQtMQ.5 dHctdGV4dC1iYXNl dHctdGV4dC1wdXJwbGUtODAw dHctbGVhZGluZy1yZWxheGVk">hola@unilearn.cl</p></div></div></div><div className="dHctcHgtNA dHctcHktOA sm:dHctY29sLXN0YXJ0LTI sm:dHctY29sLXNwYW4tMg lg:dHctY29sLXN0YXJ0LTM lg:dHctY29sLXNwYW4tMQ sm:dHctcC04 sm:dHctcHktMTA dHctcm91bmRlZC0zeGw dHctYmctcm9zZS01MA"><div className="dHctZmxleA sm:dHctZmxleC1jb2w lg:dHctZmxleC1yb3c"><div><span className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctcm91bmRlZC0yeGw dHctdy0xNA dHctaC0xNA dHctYmctcm9zZS0yMDA"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy04 dHctaC04 dHctdGV4dC1wdXJwbGUtNzAw"><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path></svg></span></div><div className="dHctZmxleC0x dHctbWwtNg sm:dHctbXQtMw lg:dHctbXQtMA sm:dHctbWwtMA lg:dHctbWwtNg"><h5 className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctdGV4dC14bA dHctZm9udC1zZW1pYm9sZA dHctdGV4dC1wdXJwbGUtOTAw">Call us</h5><p className="dHctbXQtMQ.5 dHctdGV4dC1iYXNl dHctdGV4dC1wdXJwbGUtODAw dHctbGVhZGluZy1yZWxheGVk">+1-202-555-0132</p></div></div></div></div></div>
            {/*
                    <div className="dHctbXQtMTY lg:dHctbXgtYXV0bw lg:dHctbXQtMjQ dHctcm91bmRlZC0zeGw 
                    lg:dHctbWF4LXctc2NyZWVuLXhs">
                        <iframe className="dHctdy1mdWxs dHctcm91bmRlZC0zeGw" 
                        src="https://www.google.com/maps/embed?
                        pb=!1m18!1m12!1m3!1d3086.1624232420972!2d-76.62270638437457!3d39.329905428938126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c804df8502f88d%3A0x303d58494fa04c66!2sJohns%20Hopkins%20University!5e0!3m2!1sen!2sus!4v1629758627091!5m2!1sen!2sus" height="600" 
            style={{ border: 0 }}
            allowfullscreen="" loading="lazy"></iframe></div>
            */}
            </section>

    );
};

export default ContactInformation;