import React from 'react';
import ContactForm from '../components/Contact/ContactForm';
import ContactInformation from '../components/Contact/ContactInformation';
const ContactSection = () => {
  return (
    <>
    <ContactForm />
    <ContactInformation />
    </>
);
};

export default ContactSection;
