import React from 'react';
import SharePointIntegrationDetails from '../components/SharePointIntegrationDetails'
const SharePointIntegration = () => {
  return (
    <>
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            Servicios de Integración con SharePoint
          </h2>
          <p className="text-lg text-gray-600">
            Optimice la colaboración y gestión documental en su empresa con nuestros servicios de integración personalizados.
          </p>
        </div>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">¿Qué es SharePoint?</h3>
              <p className="text-gray-600">
                SharePoint es una solución de Microsoft que facilita la colaboración y gestión documental. Se destaca cuando se complementa con servicios personalizados de desarrollo e integración.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Características Clave</h3>
              <ul className="list-disc list-inside text-gray-600">
                <li>Intranet y portales personalizados</li>
                <li>Gestión de archivos y documentos</li>
                <li>Funcionalidad colaborativa</li>
                <li>Gestión de proyectos</li>
                <li>Gestión de derechos de información</li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Servicios de Integración</h3>
              <p className="text-gray-600">
                Conectamos SharePoint con sus sistemas internos, como Teams, Microsoft Dynamics, calendarios y CRM, y creamos enlaces con software personalizado para una colaboración fluida.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Beneficios de la Integración</h3>
              <p className="text-gray-600">
                La integración con SharePoint optimiza los procesos internos, mejora la eficiencia de los equipos y elimina la frustración de manejar múltiples software desconectados.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Opciones de Integración</h3>
              <ul className="list-disc list-inside text-gray-600">
                <li>Integración con CMS</li>
                <li>Integración con ERP</li>
                <li>Integración con CRM</li>
                <li>Integración con BI</li>
                <li>Otros sistemas y aplicaciones</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
      );
};

export default SharePointIntegration;
