import React from 'react';

const clients = [
  { id: 1, name: 'Universidad Andrés Bello', logo: '/static/media/logos/unab-logo.png' },
  { id: 2, name: 'Universidad Católica de Chile', logo: '/static/media/logos/puc-logo.svg' },
  { id: 3, name: 'Entretec', logo: '/static/media/logos/entretec-logo.png' },
  { id: 4, name: 'Claro Chile', logo: '/static/media/logos/png-transparent-claro-hd-logo.png' }
];

const Clientes = () => {
  return (
    <section className="py-24 bg-gray-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-14 -mt-8 text-center">
        <h1 className=" dHctbXQtNA dHctdGV4dC1wdXJwbGUtOTAw sm:dHctbXQtNQ h1 
        lg:dHctbWF4LXctbm9uZQ h2">Nuestros Clientes</h1>
          <p className="dHctbWF4LXctMnhs dHctbXgtYXV0bw dHctbXQtNA dHctdGV4dC14bA dHctbGVhZGluZy1yZWxheGVk dHctdGV4dC1jZW50ZXI dHctdGV4dC1wdXJwbGUtODAw sm:dHctbXQtNQ">
          Hemos tenido el privilegio de trabajar con una amplia gama de clientes en diferentes industrias.          
          
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12 justify-center items-center">
          {clients.map(client => (
            <div key={client.id} className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
              <img src={client.logo} alt={client.name} className="h-20 mb-4"/>
              <h3 className="text-xl text-center font-semibold text-gray-700">{client.name}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Clientes;
