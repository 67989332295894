import React from 'react';
import ContactDetails from './Contact/ContactDetails';

const Footer = () => {
  return (
      <footer 
        className="pt-10 dHctcHgtNA dHctcHQtMTY dHctc3BhY2UteS04 dHctYmcteWVsbG93LTEwMA dHctZGl2aWRlLXk sm:dHctcHQtMjA sm:dHctcHgtNg lg:dHctcHgtOA dHctZGl2aWRlLXB1cnBsZS00MDAvMjA">
          <div className="dHctZ3JpZA dHctbWF4LXctbWQ dHctbXgtYXV0bw dHctZ2FwLXktOA sm:dHctZ2FwLXktMTI sm:dHctZ2FwLXgtOA md:dHctZ2FwLXgtMTI sm:dHctbWF4LXctbm9uZQ lg:dHctbWF4LXctc2NyZWVuLTJ4bA 
          sm:dHctZ3JpZC1jb2xzLTI lg:dHctZ3JpZC1jb2xzLTEx lg:dHctZ2FwLTg xl:dHctZ2FwLTEy">
          <div className="dHctZmxleA dHctZmxleC1jb2w lg:dHctbXgtYXV0bw lg:dHctY29sLXNwYW4tNA"><div className="dHctZmxleA dHctaXRlbXMtY2VudGVy">
            <div className="dHctZmxleC1ncm93LTA dHctZmxleC1zaHJpbmstMA dHctdy02MA"><a href="/">
            <img alt="Bright" loading="lazy" width="562" height="178" decoding="async" data-nimg="1" className="dHctaC1hdXRv"  
            style={{ color: 'transparent' }}  
            src="/Unilearn.png" />
            </a>
            </div>
            </div>
            <div className="dHctbXQtNg dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw">
            Ofrecemos soluciones tecnológicas avanzadas para instituciones educativas, facilitando la transformación digital y mejorando la experiencia de aprendizaje. Síguenos en nuestras redes sociales y mantente al día con nuestras últimas innovaciones.            </div>
            <div className="dHctdy1mdWxs dHctbXQtNQ lg:dHctbXQtNg"><div className="dHctZmxleA dHctanVzdGlmeS1zdGFydA dHctc3BhY2UteC00">
              <a className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctdy0xMA dHctaC0xMA dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctYmctcHVycGxlLTUwMA 
              dHctcm91bmRlZC1mdWxs hover:dHctYmctcHVycGxlLTYwMA" href="#0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" 
                stroke-linejoin="round" className="dHctdy01 dHctaC01 dHctdGV4dC13aGl0ZQ">
                <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"></path><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
              <path d="M16.5 7.5l0 .01"></path></svg></a>
              <a className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctdy0xMA dHctaC0xMA dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctYmctcHVycGxlLTUwMA dHctcm91bmRlZC1mdWxs hover:dHctYmctcHVycGxlLTYwMA"
               href="#0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                 stroke-linejoin="round" className="dHctdy01 dHctaC01 dHctdGV4dC13aGl0ZQ"><path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                </svg>
              </a><a className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctdy0xMA dHctaC0xMA dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctYmctcHVycGxlLTUwMA dHctcm91bmRlZC1mdWxs hover:dHctYmctcHVycGxlLTYwMA" href="#0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" 
                  stroke-linejoin="round" className="dHctdy01 dHctaC01 dHctdGV4dC13aGl0ZQ">
                <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c0 -.249 1.51 -2.772 1.818 -4.013z">
                  </path>
                </svg>
            </a>
           </div>
          </div>
        </div>
        <div className="dHctZmxleC1zaHJpbms sm:dHctb3JkZXItMw lg:dHctb3JkZXItbm9uZQ lg:dHctY29sLXNwYW4tMg">
            <h6 className="dHctcmVsYXRpdmU dHctdGV4dC14bA dHctZm9udC1ib2xk dHctdHJhY2tpbmctd2lkZQ dHctdGV4dC1wdXJwbGUtOTAw">
              <span className="dHctcmVsYXRpdmU">Servicios</span>
              <span className="dHctYWJzb2x1dGU dHctbGVmdC0w dHctei0xMA dHctdy0xMg dHctaC0x dHctcm91bmRlZC1sZw dHctYmctZ3JhZGllbnQtdG8tcg dHctZnJvbS15ZWxsb3ctNDAw 
                dHctdG8teWVsbG93LTUwMA LXR3LWJvdHRvbS0x"></span></h6>
                <ul className="dHctbXQtNg dHctdGV4dC1sZw dHctZGl2aWRlLXk dHctZGl2aWRlLXB1cnBsZS00MDAvMjA">
                  <li className="dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ hover:dHctdGV4dC1wdXJwbGUtNjAw dHctcGItMg">
                      <a href="/servicios/lms-moodle-canvas">Canvas y Moodle en la Nube</a>
                </li>
                <li className="dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ hover:dHctdGV4dC1wdXJwbGUtNjAw dHctcHktMg">
                      <a href="/servicios/servicios-procesos-aut">Digitalización y Automatización de Procesos</a></li>
                        <li className="dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ hover:dHctdGV4dC1wdXJwbGUtNjAw dHctcHktMg">
                              <a href="/servicios/sharepoint-servicios-integracion">Servicios de Integración con SharePoint</a></li>
                        </ul></div>
              <div className="dHctZmxleC1zaHJpbms sm:dHctb3JkZXItNA lg:dHctb3JkZXItbm9uZQ lg:dHctY29sLXNwYW4tMg"><h6 className="dHctcmVsYXRpdmU dHctdGV4dC14bA dHctZm9udC1ib2xk dHctdHJhY2tpbmctd2lkZQ dHctdGV4dC1wdXJwbGUtOTAw">
                  <span className="dHctcmVsYXRpdmU">
                      Enlaces del sitio</span>
                      <span className="dHctYWJzb2x1dGU dHctbGVmdC0w dHctei0xMA dHctdy0xMg dHctaC0x dHctcm91bmRlZC1sZw dHctYmctZ3JhZGllbnQtdG8tcg dHctZnJvbS15ZWxsb3ctNDAw dHctdG8teWVsbG93LTUwMA LXR3LWJvdHRvbS0x">
                        </span>
                    </h6>
                    <ul className="dHctbXQtNg dHctdGV4dC1sZw dHctZGl2aWRlLXk dHctZGl2aWRlLXB1cnBsZS00MDAvMjA">
                        <li className="dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ hover:dHctdGV4dC1wdXJwbGUtNjAw dHctcGItMg">
                            <a href="/">Inicio</a></li>
                            <li className="dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ hover:dHctdGV4dC1wdXJwbGUtNjAw dHctcHktMg">
                              <a href="/servicios">Servicios</a></li>
                            <li className="dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ hover:dHctdGV4dC1wdXJwbGUtNjAw dHctcHktMg">
                              <a href="/precios">Precios</a></li>
                            <li className="dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ hover:dHctdGV4dC1wdXJwbGUtNjAw dHctcHktMg">
                              <a href="/gallery">Portafolio</a></li>
                            <li className="dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ hover:dHctdGV4dC1wdXJwbGUtNjAw dHctcHktMg">
                              <a href="/contactenos">Contacto</a></li>
                              
                          </ul>
                          
                          
                          </div>
                          <div className="sm:dHctb3JkZXItMg lg:dHctb3JkZXItbm9uZQ lg:dHctY29sLXNwYW4tMw lg:dHctbXgtYXV0bw -ml-40">
                            <h6 className="dHctcmVsYXRpdmU dHctdGV4dC14bA dHctZm9udC1ib2xk dHctdHJhY2tpbmctd2lkZQ dHctdGV4dC1wdXJwbGUtOTAw">
                              <span className="dHctcmVsYXRpdmU dHctei0yMA">Contáctenos</span>
                              <span className="dHctYWJzb2x1dGU dHctbGVmdC0w dHctei0xMA dHctdy0xMg dHctaC0x dHctcm91bmRlZC1sZw dHctYmctZ3JhZGllbnQtdG8tcg dHctZnJvbS15ZWxsb3ctNDAw dHctdG8teWVsbG93LTUwMA LXR3LWJvdHRvbS0x">
                                </span></h6>
                                <ContactDetails displayMode={'vertical'}/>
                                
            </div></div>
            <div className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1iZXR3ZWVu dHctbWF4LXctbWQ dHctcHktOA dHctbXgtYXV0bw sm:dHctZmxleC1yb3c sm:dHctbWF4LXctbm9uZQ lg:dHctbWF4LXctc2NyZWVuLTJ4bA"><span className="dHctdGV4dC1iYXNl dHctdGV4dC1wdXJwbGUtODAwLzkw">© 2024 Bright School. All rights reserved.</span>
            <p className="dHctbXQtMA.5 dHctZmxleA dHctaXRlbXMtY2VudGVy dHctdGV4dC1wdXJwbGUtODAwLzkw">Hecho por <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dHctdy01 dHctaC01 dHctbXgtMQ"><path d="M3 14c.83 .642 2.077 1.017 3.5 1c1.423 .017 2.67 -.358 3.5 -1c.83 -.642 2.077 -1.017 3.5 -1c1.423 -.017 2.67 .358 3.5 1"></path><path d="M8 3a2.4 2.4 0 0 0 -1 2a2.4 2.4 0 0 0 1 2"></path><path d="M12 3a2.4 2.4 0 0 0 -1 2a2.4 2.4 0 0 0 1 2"></path><path d="M3 10h14v5a6 6 0 0 1 -6 6h-2a6 6 0 0 1 -6 -6v-5z"></path><path d="M16.746 16.726a3 3 0 1 0 .252 -5.555"></path></svg><span>por <a className="dHctdGV4dC1wdXJwbGUtNzAw hover:dHctdGV4dC1wdXJwbGUtOTAw hover:dHctdW5kZXJsaW5l" target="_blank" href="https://unilearn.cl/">Unilearn Latam</a></span></p></div></footer>
  );
};

export default Footer;
