import React from 'react';

const TablaDePreciosCanvas = () => {
  return (
    <section className="py-24 bg-gray-50">
      <div className="container mx-auto px-6 lg:px-8">
        <div className="mb-16 text-center">
          <h2 className="text-5xl font-bold text-gray-800">Planes de Canvas LMS</h2>
          <p className="mt-4 text-xl text-gray-600">
            Selecciona el plan que mejor se adapte a las necesidades de tu organización.
          </p>
        </div>
        
        <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {/* Plan Básico */}
          <div className="bg-white rounded-xl shadow-md overflow-hidden">
            <div className="p-8">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">Básico</h3>
              <div className="flex items-baseline mb-6">
                <span className="text-4xl font-bold text-gray-800">$200.000</span>
                <span className="ml-1 text-gray-600">/mes</span>
              </div>
              <ul className="space-y-4 mb-6 text-gray-600">
                <li>Hasta <span className="font-medium">100 usuarios</span></li>
                <li><span className="font-medium">50 GB</span> de almacenamiento</li>
                <li>Soporte por correo electrónico</li>
                <li>Certificado SSL incluido</li>
                <li>Actualizaciones automáticas</li>
              </ul>
              <a href="/contactenos">
              <button className="w-full py-3 px-6 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300">
                Elegir Plan
              </button>
              </a>
            </div>
          </div>
          
          {/* Plan Profesional */}
          <div className="bg-white rounded-xl shadow-lg border-4 border-blue-600 overflow-hidden transform scale-105">
            <div className="p-8">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">Profesional</h3>
              <div className="flex items-baseline mb-6">
                <span className="text-4xl font-bold text-gray-800">$300.000</span>
                <span className="ml-1 text-gray-600">/mes</span>
              </div>
              <ul className="space-y-4 mb-6 text-gray-600">
                <li>Hasta <span className="font-medium">500 usuarios</span></li>
                <li><span className="font-medium">200 GB</span> de almacenamiento</li>
                <li>Soporte 24/7 por correo y chat</li>
                <li>Certificado SSL incluido</li>
                <li>Integración con plugins populares</li>
                <li>Copias de seguridad diarias</li>
              </ul>
              <a href="/contactenos">
              <button className="w-full py-3 px-6 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300">
                Elegir Plan
              </button>
              </a>
            </div>
          </div>
          
          {/* Plan Empresarial */}
          <div className="bg-white rounded-xl shadow-md overflow-hidden">
            <div className="p-8">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">Empresarial</h3>
              <div className="flex items-baseline mb-6">
                <span className="text-4xl font-bold text-gray-800">$600.000</span>
                <span className="ml-1 text-gray-600">/mes</span>
              </div>
              <ul className="space-y-4 mb-6 text-gray-600">
                <li>Usuarios <span className="font-medium">1000+</span></li>
                <li><span className="font-medium">1 TB</span> de almacenamiento</li>
                <li>Soporte dedicado 24/7</li>
                <li>Certificado SSL personalizado</li>
                <li>Integraciones avanzadas y personalizadas</li>
                <li>Consultoría y capacitación personalizada</li>
                <li>Copias de seguridad en tiempo real</li>
              </ul>
              <a href="/contactenos">
              <button className="w-full py-3 px-6 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300">
                Elegir Plan
              </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TablaDePreciosCanvas;
