import React from 'react';
import ServiciosPrecios from './ServiciosPrecios'
const ServiciosLMS = () => {
  return (
    <>
<div class="dHctYmctZ3JhZGllbnQtdG8tYg dHctZnJvbS1wdXJwbGUtNTA dHctdG8teWVsbG93LTEwMA" style={{padding: '2.5rem'}}></div>
   <section className="dHctcHgtNA dHctcGItMTY dHctb3ZlcmZsb3ctaGlkZGVu dHctYmcteWVsbG93LTEwMA sm:dHctcGItMjQ sm:dHctcHgtNg lg:dHctcHgtOA"><div className="dHctbWF4LXctc2NyZWVuLXhs dHctbXgtYXV0bw">
    <div className="dHctcmVsYXRpdmU">
            <h2 className="dHctbWF4LXctNHhs dHctbXgtYXV0bw dHctdGV4dC1jZW50ZXI dHctdGV4dC1wdXJwbGUtOTAw h2" >
            Soluciones LMS en la Nube: Servicio SaaS con Moodle y Canvas
        </h2>
        <p style={{maxWidth:'60rem'}} className=" dHctbXgtYXV0bw dHctbXQtNA dHctdGV4dC14bA dHctbGVhZGluZy1yZWxheGVk dHctdGV4dC1jZW50ZXI dHctdGV4dC1wdXJwbGUtODAw">
        Ofrecemos un servicio de LMS (Learning Management System) en la nube como solución SaaS (Software as a Service), especializado en las plataformas Moodle y Canvas. Nuestro servicio permite a instituciones educativas y organizaciones gestionar y entregar contenido educativo de manera eficiente, sin preocuparse por la infraestructura tecnológica. Nos encargamos de la implementación, mantenimiento, actualizaciones y soporte técnico, garantizando que nuestros clientes puedan centrarse en lo más importante: la enseñanza y el aprendizaje
        
            </p><div className=" dHctbXgtYXV0bw dHctbXQtMTI"  style={{maxWidth:'60rem'}} > 
    <ul  className="dHctZmxleA dHctZmxleC13cmFw dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI LXR3LW14LTM LXR3LW15LTI dHctdGV4dC1sZw dHctdGV4dC1wdXJwbGUtODAw">
      <li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" loading="lazy" width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Gestión de cursos y contenido</span></li><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" 
    loading="lazy" 
    width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Seguimiento del progreso y análisis</span></li><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" loading="lazy" width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Evaluaciones y calificaciones en línea</span></li><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" loading="lazy" width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Comunicación y colaboración integrada</span></li><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" loading="lazy" width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Integración con herramientas externas y API</span></li><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" loading="lazy" width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Personalización y adaptabilidad del entorno</span></li><li className="dHctZmxleA dHctaXRlbXMtY2VudGVy dHctbXgtMw dHctbXktMg">
    <img alt="" loading="lazy" width="500" height="475" decoding="async" data-nimg="1" 
    className="dHctZmxleC1zaHJpbmstMA dHctbXItMw dHctdy03 dHctaC03" style={{color: 'transparent'}} 
    src="/_next/static/media/checkmark.b1505a8c.svg" /><span>Soporte para aprendizaje móvil y multiplataforma</span></li></ul></div></div>
    
    <div className="dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMTY lg:dHctbWF4LXctbm9uZQ lg:dHctZ3JpZA lg:dHctZ3JpZC1jb2xzLTEy lg:dHctZ2FwLXgtMTQ xl:dHctZ2FwLXgtMjA 2xl:tw-gap-x-24 sm:dHctbXQtMjA lg:dHctbXQtMjQ"><div className="dHctcmVsYXRpdmU lg:dHctY29sLXNwYW4tNg"><div className="dHctcmVsYXRpdmU sm:dHctcGwtMzY lg:dHctcGwtMjA xl:dHctcGwtMzI"><div className="dHctcmVsYXRpdmU dHctYXNwZWN0LXctMw dHctYXNwZWN0LWgtNA dHctcm91bmRlZC0yeGw">
    <img alt="Kid stacking blocks" loading="lazy" decoding="async" data-nimg="fill" 
    className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctcm91bmRlZC0yeGw" 
    sizes="(min-width: 1280px) 29.5rem, (min-width: 1024px) calc(50vw - 8.75rem), (min-width: 640px) 27rem, calc(100vw - 2rem)" 
    src="/static/media/images/view-of-hands-of-analysts-or-brokers-with-gadgets-2023-11-27-04-56-49-utc.jpg"/>
    </div></div></div>
    <div className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctbXQtMTY lg:dHctbXQtMA lg:dHctY29sLXNwYW4tNg sm:dHctbXQtNDQ"><div><span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">El LMS más popular</span></div>
        <h3 className="dHctbXQtNA dHctdGV4dC1wdXJwbGUtOTAw sm:dHctbXQtNQ h3">
        Moodle LMS        </h3>
    <p className="dHctbWF4LXctMnhs dHctbXQtMw dHctdGV4dC1sZw dHctbGVhZGluZy1sb29zZQ dHctdGV4dC1wdXJwbGUtODAw">
    Moodle LMS es una plataforma de código abierto diseñada para proporcionar un entorno educativo flexible y escalable. Con Moodle, las instituciones educativas pueden crear y gestionar cursos en línea, evaluar a los estudiantes y ofrecer una experiencia de aprendizaje personalizada. Sus características incluyen herramientas de colaboración, gestión de contenido, evaluación y seguimiento de progreso, todo dentro de una interfaz intuitiva que facilita la enseñanza y el aprendizaje en línea.

Moodle se integra fácilmente con otros sistemas y servicios educativos, lo que permite a las instituciones optimizar sus flujos de trabajo. Además, su arquitectura modular permite a los administradores personalizar la plataforma según sus necesidades específicas. El soporte para múltiples idiomas y la capacidad de manejar una gran cantidad de usuarios lo convierten en una opción ideal para instituciones de todos los tamaños.    </p>
    <div className="dHctbXQtNg"><a className="dHctZm9udC1tZWRpdW0 dHctcmVsYXRpdmU dHctbGVhZGluZy1ub3JtYWw dHctaW5saW5lLWZsZXg dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctcm91bmRlZC1mdWxs dHctb3V0bGluZS1ub25l dHctZ3JvdXA dHctdGV4dC13aGl0ZQ dHctYmctcHVycGxlLTYwMA hover:dHctYmctcHVycGxlLTUwMA dHctcHgtNQ dHctcHktMg.5 dHctdGV4dC1iYXNl" href="/about">Conoce más<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctbWwtMw group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ"><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg></a></div></div></div><div className="dHctbWF4LXcteGw dHctbXgtYXV0bw dHctbXQtMTY lg:dHctbWF4LXctbm9uZQ lg:dHctZ3JpZA lg:dHctZ3JpZC1jb2xzLTEy lg:dHctZ2FwLXgtMTQ xl:dHctZ2FwLXgtMjA 2xl:tw-gap-x-24 sm:dHctbXQtNDQ lg:dHctbXQtNTY xl:dHctbXQtNjA 2xl:tw-mt-64"><div className="dHctcmVsYXRpdmU lg:dHctY29sLXNwYW4tNg lg:dHctb3JkZXItMg"><div className="dHctcmVsYXRpdmU sm:dHctcHItMzY lg:dHctcHItMjA xl:dHctcHItMzI">
      <div className="dHctcmVsYXRpdmU dHctYXNwZWN0LXctMw dHctYXNwZWN0LWgtNA dHctcm91bmRlZC0yeGw">
    <img alt="Child covered in paint" loading="lazy" decoding="async" data-nimg="fill" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctcm91bmRlZC0yeGw" 
    sizes="(min-width: 1280px) 29.5rem, (min-width: 1024px) calc(50vw - 8.75rem), (min-width: 640px) 27rem, calc(100vw - 2rem)" 
    src="/static/media/images/illustration-stationery-on-office-desk-2023-11-27-05-26-35-utc.jpg" />
    </div><div className="dHctYWJzb2x1dGU dHctaGlkZGVu sm:dHctYmxvY2s sm:dHctdy03Mg sm:dHctaC03Mg lg:dHctdy02NA lg:dHctaC02NA xl:dHctdy03Mg xl:dHctaC03Mg 2xl:tw-w-80 2xl:tw-h-80 dHctcm91bmRlZC0zeGw dHctcmlnaHQtMA dHctdG9wLTA sm:LXR3LXRyYW5zbGF0ZS15LTEvMw">
    <img alt="Children eating" loading="lazy" decoding="async" data-nimg="fill" className="dHctYWJzb2x1dGU dHctaW5zZXQtMA dHctb2JqZWN0LWNvdmVy dHctdy1mdWxs dHctaC1mdWxs dHctcm91bmRlZC0zeGw" 
     sizes="(min-width: 1536px) 20rem, (min-width: 1280px) 18rem, (min-width: 1024px) 16rem, (min-width: 640px) 18rem" 
    src="/static/media/universitarios-612x612.jpg" />
    </div></div></div><div className="dHctZmxleA dHctZmxleC1jb2w dHctanVzdGlmeS1jZW50ZXI dHctbXQtMTY lg:dHctbXQtMA lg:dHctY29sLXNwYW4tNg sm:dHctbXQtMjA lg:dHctb3JkZXItMQ"><div>
        <span className="dHctaW5saW5lLWJsb2Nr dHctcHgtNA dHctcHktMg dHctZm9udC1tZWRpdW0 dHctdGV4dC1wdXJwbGUtNzAw dHctYmctcHVycGxlLTIwMA dHctcm91bmRlZC1mdWxs dHctc2hhZG93LW1k LXR3LXJvdGF0ZS0x">
            LMS Canvas & Moodle
        </span>
        </div>
        <h3 className="dHctbXQtNA dHctdGV4dC1wdXJwbGUtOTAw sm:dHctbXQtNQ h3">
      LMS Canvas 
       </h3><p className="dHctbWF4LXctMnhs dHctbXQtMw dHctdGV4dC1sZw dHctbGVhZGluZy1sb29zZQ dHctdGV4dC1wdXJwbGUtODAw">
       Canvas LMS es una plataforma de gestión de aprendizaje basada en la nube, diseñada para facilitar la enseñanza y el aprendizaje a través de herramientas avanzadas y una interfaz moderna. Canvas ofrece una variedad de características que incluyen la creación y gestión de cursos, herramientas de comunicación, calificación y evaluación en línea, y opciones de personalización que se adaptan a las necesidades de las instituciones educativas.

Una de las principales ventajas de Canvas es su integración con otras aplicaciones y sistemas educativos, lo que permite a las instituciones optimizar sus operaciones y mejorar la experiencia de los usuarios. Además, Canvas ofrece un soporte técnico robusto y recursos de formación para garantizar que tanto los docentes como los estudiantes saquen el máximo provecho de la plataforma.
                </p><div className="dHctbXQtNg">
          <a className="dHctZm9udC1tZWRpdW0 dHctcmVsYXRpdmU dHctbGVhZGluZy1ub3JtYWw dHctaW5saW5lLWZsZXg dHctaXRlbXMtY2VudGVy dHctanVzdGlmeS1jZW50ZXI dHctZHVyYXRpb24tMzAw dHctZWFzZS1pbi1vdXQ dHctcm91bmRlZC1mdWxs dHctb3V0bGluZS1ub25l dHctZ3JvdXA dHctdGV4dC13aGl0ZQ dHctYmctcHVycGxlLTYwMA hover:dHctYmctcHVycGxlLTUwMA dHctcHgtNQ dHctcHktMg.5 dHctdGV4dC1iYXNl" href="/about">Conoce más<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="dHctdy02 dHctaC02 dHctbWwtMw group-hover:dHctYW5pbWF0ZS1ob3Jpem9udGFsLWJvdW5jZQ"><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg></a></div></div></div></div></section>

    <ServiciosPrecios />
    </>
  );
};

export default ServiciosLMS;
